import React from 'react'
import { Tooltip } from '@material-ui/core'
import { Archive } from '@material-ui/icons'

const ArchivedIcon = (props) => {
  return (
    <Tooltip title='archived' arrow>
      <Archive
        color='primary'
        size='small'
        style={{ marginLeft: '8px' }}
        {...props}
      />
    </Tooltip>
  )
}

export default ArchivedIcon
