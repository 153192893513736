import React, { useState } from 'react'
import {
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import UserDialog from './UserDialog'
import DeleteUserAlert from './DeleteUserAlert'

const useStyles = makeStyles((theme) => ({
  userDetails: {
    display: 'block',
  },
  userHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  userUsername: {
    marginRight: theme.spacing(2),
    minWidth: theme.spacing(12),
  },
  userName: {
    flexGrow: '1',
  },
}))

const UserList = ({ users }) => {
  const [expanded, setExpanded] = useState(false)

  const classes = useStyles()

  const handleChange = (panel) => (ev, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <>
      {users?.length > 0 ? (
        users.map((user) => {
          return (
            <Accordion
              expanded={expanded === user.username}
              key={user.username}
              onChange={handleChange(user.username)}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <div className={classes.userHeader}>
                  <Chip
                    color='secondary'
                    label={user.username}
                    className={classes.userUsername}
                  />
                  <Typography className={classes.userName} variant='h5'>
                    {user.firstName} {user.lastName}
                  </Typography>
                  <UserDialog user={user} />
                  <DeleteUserAlert user={user} />
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.userDetails}>
                <Typography variant='h6'>Account Details</Typography>
                <Typography>
                  <em>Coming soon!</em>
                </Typography>
              </AccordionDetails>
            </Accordion>
          )
        })
      ) : (
        <Typography>No users found</Typography>
      )}
    </>
  )
}

export default UserList
