import React from 'react'
import { Fab, makeStyles } from '@material-ui/core'
import { Add } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const FabAdd = (props) => {
  const classes = useStyles()

  return (
    <Fab color='primary' onClick={props.onClick} variant='extended' {...props}>
      <Add className={classes.icon} />
      {props.prefix ?? 'Add'} {props.item}
    </Fab>
  )
}

export default FabAdd
