import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import PageHeader from '../PageHeader'
import RecipeEditor from './RecipeEditor'
import RecipeViewer from './RecipeViewer'
import RecipesTable from './RecipesTable'
import Spinner from '../Spinner'
import agent from '../../agent'

import {
  RECIPES_PAGE_LOADED,
  RECIPES_PAGE_UNLOADED,
  RECIPE_VIEW_UNLOADED,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  ingredients: state.ingredients.ingredients,
  pageLoaded: state.recipes.pageLoaded,
  viewer: state.recipes.viewer,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({
      type: RECIPES_PAGE_LOADED,
      payload: Promise.all([
        agent.Recipes.getAll(),
        agent.Ingredients.getAll(),
        agent.Categories.getAll(),
      ]),
    }),
  onUnload: () => dispatch({ type: RECIPES_PAGE_UNLOADED }),
  onUnloadView: () => dispatch({ type: RECIPE_VIEW_UNLOADED }),
})

const Recipes = ({
  ingredients,
  onLoad,
  onUnload,
  onUnloadView,
  pageLoaded,
  viewer,
}) => {
  const [accordionOpen, setAccordionOpen] = useState(false)

  useEffect(() => {
    onLoad()

    return () => {
      onUnload()
    }
  }, [])

  return (
    <>
      <PageHeader title={viewer.recipe ? 'View Recipe' : 'Manage Recipes'}>
        {viewer.recipe ? (
          <Button color='secondary' onClick={onUnloadView}>
            Manage recipes
          </Button>
        ) : (
          <RecipeEditor />
        )}
      </PageHeader>
      {pageLoaded ? (
        viewer.recipe ? (
          <RecipeViewer ingredients={ingredients} recipe={viewer.recipe} />
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <RecipesTable filter='unarchived' />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h5' gutterBottom>
                Archived Recipes
              </Typography>
              <Accordion
                onChange={(ev, expanded) => setAccordionOpen(expanded)}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant='body1'>
                    Click to {accordionOpen ? 'hide' : 'show'} archived recipes
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'block' }}>
                  <RecipesTable filter='archived' />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        )
      ) : (
        <Spinner />
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Recipes)
