import React, { useEffect, useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

const RecipeSelector = ({ name, onChange, recipes, values }) => {
  const [options, setOptions] = useState([])

  const mappedOptions = recipes
    .filter((recipe) => recipe.isMeal === false)
    .map((recipe) => {
      const firstLetter = recipe.title[0].toUpperCase()
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
        ...recipe,
      }
    })

  const handleChange = (ev, values) => {
    onChange(name, values)
  }

  useEffect(() => {
    setOptions(mappedOptions)
  }, [recipes])

  return (
    <Autocomplete
      autoHighlight
      autoSelect
      getOptionLabel={(option) => {
        return option.title
      }}
      getOptionSelected={(option, value) => option._id === value._id}
      groupBy={(option) => option.firstLetter}
      multiple
      noOptionsText='No recipes have been created'
      onChange={handleChange}
      options={options}
      renderInput={(params) => (
        <TextField {...params} label='Recipes' variant='outlined' />
      )}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.title, inputValue)
        const parts = parse(option.title, matches)
        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}
          </div>
        )
      }}
      value={values}
    />
  )
}

export default RecipeSelector
