import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'

import ActionButton from './ActionButton'

const BulkDeleteConfirmationAlert = ({ data, name, onDelete }) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    setOpen(false)
    onDelete(data)
  }

  return (
    <>
      <ActionButton
        action={'Delete selected ' + name + 's'}
        onClick={handleClickOpen}
      >
        <Delete />
      </ActionButton>
      <Dialog
        open={open}
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
      >
        <DialogTitle>Delete selected {name}s?</DialogTitle>
        <DialogContent>
          <DialogContentText component='div'>
            This action will permanently delete all of the selected {name}s.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color='secondary'
            variant='contained'
            autoFocus
          >
            Delete {name}s
          </Button>
          <Button onClick={handleClose} variant='contained'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BulkDeleteConfirmationAlert
