import {
  ADD_INGREDIENT,
  ADD_RECIPE,
  ASYNC_START,
  MEALS_PAGE_LOADED,
  MEALS_PAGE_UNLOADED,
  PLANS_PAGE_LOADED,
  PLANS_PAGE_UNLOADED,
  RECIPE_DIALOG_LOADED,
  RECIPE_DIALOG_UNLOADED,
  RECIPE_INGREDIENT_ONBLUR,
  RECIPES_PAGE_LOADED,
  RECIPES_PAGE_UNLOADED,
  RECIPE_VIEW_LOADED,
  RECIPE_VIEW_UNLOADED,
  DELETE_RECIPE,
  DELETE_RECIPES,
  UPDATE_RECIPE,
} from '../constants/actionTypes'

const defaultState = {
  editor: { recipe: null, ingredient: null },
  errors: null,
  recipes: [],
  inProgress: false,
  pageLoaded: false,
  viewer: { recipe: null },
}

const sortByTitle = (a, b) => a.title.localeCompare(b.title)

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_INGREDIENT:
      return {
        ...state,
        editor: { ...state.editor, ingredient: action.payload.ingredient },
      }
    case ADD_RECIPE:
      return {
        ...state,
        recipes: action.error
          ? state.recipes
          : [...state.recipes, action.payload.recipe].sort((a, b) =>
              sortByTitle(a, b)
            ),
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    case ASYNC_START:
      if (action.subtype === ADD_RECIPE || action.subtype === UPDATE_RECIPE) {
        return { ...state, errors: null, inProgress: true }
      } else if (action.subtype === RECIPES_PAGE_LOADED) {
        return { ...state, errors: null, pageLoaded: false }
      }
      break
    case DELETE_RECIPE:
      return {
        ...state,
        recipes: action.error
          ? state.recipes
          : state.recipes.filter(
              (recipe) => recipe.slug !== action.payload.recipe.slug
            ),
      }
    case DELETE_RECIPES:
      return {
        ...state,
        recipes: action.error
          ? state.recipes
          : state.recipes.filter(
              (recipe) => !action.payload.recipes.includes(recipe.slug)
            ),
      }
    case MEALS_PAGE_LOADED:
      return {
        ...state,
        recipes: action.error ? [] : action.payload[1].recipes,
      }
    case MEALS_PAGE_UNLOADED:
    case PLANS_PAGE_UNLOADED:
    case RECIPES_PAGE_UNLOADED:
      return defaultState
    case PLANS_PAGE_LOADED:
      return {
        ...state,
        recipes: action.error ? [] : action.payload[2].recipes,
      }
    case RECIPE_DIALOG_LOADED:
      return {
        ...state,
        editor: { ...state.editor, recipe: action.recipe },
      }
    case RECIPE_DIALOG_UNLOADED:
      return {
        ...state,
        editor: { ...state.editor, recipe: null, ingredient: null },
        errors: null,
      }
    case RECIPE_INGREDIENT_ONBLUR:
      return {
        ...state,
        editor: { ...state.editor, ingredient: null },
      }
    case RECIPES_PAGE_LOADED:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        recipes: action.error ? [] : action.payload[0].recipes,
        inProgress: false,
        pageLoaded: true,
      }

    case RECIPE_VIEW_LOADED:
      return { ...state, viewer: { ...state.viewer, recipe: action.recipe } }
    case RECIPE_VIEW_UNLOADED:
      return { ...state, viewer: { ...state.viewer, recipe: null } }
    case UPDATE_RECIPE:
      return {
        ...state,
        editor: { ...state.editor, recipe: null },
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        recipes: action.error
          ? state.recipes
          : state.recipes
              .map((recipe) =>
                recipe._id === action.payload.recipe._id
                  ? action.payload.recipe
                  : recipe
              )
              .sort((a, b) => sortByTitle(a, b)),
      }
    default:
      return state
  }

  return state
}
