import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from './reducers/auth'
import categories from './reducers/categories'
import common from './reducers/common'
import ingredients from './reducers/ingredients'
import meals from './reducers/meals'
import nav from './reducers/nav'
import notifier from './reducers/notifier'
import plans from './reducers/plans'
import recipes from './reducers/recipes'
import settings from './reducers/settings'
import stores from './reducers/stores'
import users from './reducers/users'

export default (history) =>
  combineReducers({
    auth,
    categories,
    common,
    ingredients,
    meals,
    nav,
    notifier,
    plans,
    recipes,
    settings,
    stores,
    users,
    router: connectRouter(history),
  })
