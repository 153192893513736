import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { MTableAction } from '@material-table/core'
import MaterialTable from 'material-table'
import { Paper } from '@material-ui/core'

// import MaterialTable, { MTableAction } from '@material-table/core'
// @todo: Not sure why we were using this MaterialTable, but it does not support data exporting.
// Everything works with this new table, but there is a warning:
// Failed prop type: Invalid prop `actions[3]` supplied to `MaterialTable`.

import BulkDeleteConfirmationAlert from '../BulkDeleteConfirmationAlert'

import agent from '../../agent'

import {
  DELETE_MEAL,
  DELETE_MEALS,
  MEAL_DIALOG_LOADED,
  UPDATE_MEAL,
} from '../../constants/actionTypes'
import { MEAL_TYPES } from '../../constants/mealTypes'

const mapStateToProps = (state) => ({
  meals: state.meals.meals,
  recipes: state.recipes.recipes,
})

const mapDispatchToProps = (dispatch) => ({
  onDelete: (meal) =>
    dispatch({
      type: DELETE_MEAL,
      payload: agent.Meals.delete(meal),
      snackbar: { message: 'Meal deleted', variant: 'success' },
    }),
  onDeleteMany: (slugs) =>
    dispatch({
      type: DELETE_MEALS,
      payload: agent.Meals.deleteMany(slugs),
      snackbar: {
        message: `${slugs.length} meal${slugs.length > 1 ? 's' : ''} deleted`,
        variant: 'success',
      },
    }),
  onLoadUpdate: (meal) => dispatch({ type: MEAL_DIALOG_LOADED, meal }),
  onUpdateArchiveStatus: (meal, status) =>
    dispatch({
      type: UPDATE_MEAL,
      payload: agent.Meals.update(meal),
      snackbar: { message: `Meal ${status}`, variant: 'success' },
    }),
})

const MealsTable = ({
  filter,
  meals,
  onDelete,
  onDeleteMany,
  onLoadUpdate,
  onUpdateArchiveStatus,
  recipes,
}) => {
  const [data, setData] = useState([])

  const [columns] = useState([
    { title: 'Title', field: 'title' },
    {
      title: 'Meal Type',
      field: 'mealType',
      lookup: MEAL_TYPES.reduce(
        (obj, item) => ((obj[item.toLowerCase()] = item), obj),
        {}
      ),
    },
  ])

  const getRecipesAsMeals = () =>
    recipes.filter((recipe) => recipe.isMeal === true)

  const handleArchiveClick = (meal) => {
    const status = meal.archived ? 'unarchived' : 'archived'
    onUpdateArchiveStatus({ ...meal, archived: !meal.archived }, status)
  }

  const handleDeleteManyClick = (data) => {
    const slugs = data.map((row) => row.slug)
    onDeleteMany(slugs)
  }

  const handleDuplicateMealClick = (meal) => {
    meal._id = ''
    onLoadUpdate(meal)
  }

  const handleLoadUpdateClick = (meal) => {
    onLoadUpdate(meal)
  }

  useEffect(() => {
    const recipesAsMeals = getRecipesAsMeals()
    const data = [...recipesAsMeals, ...meals].sort((a, b) =>
      a.title.localeCompare(b.title)
    )

    // Filter meals based on archive status, or show all meals if no filter given
    //  * allowed options include 'archived' and 'unarchived'
    const filteredMeals =
      filter === 'archived'
        ? data.filter((meal) => meal.archived === true)
        : filter === 'unarchived'
        ? data.filter((meal) => meal.archived === false)
        : data

    setData(filteredMeals)
  }, [meals, recipes])

  return (
    <MaterialTable
      title='Meals'
      columns={columns}
      data={data}
      components={{
        Action: (props) => {
          if (typeof props.action.icon === 'function') {
            return props.action.icon(props.data)
          }
          return <MTableAction {...props} />
        },
        Container: (props) => (
          <Paper {...props} elevation={filter === 'archived' ? 0 : 1} />
        ),
      }}
      localization={{
        body: {
          emptyDataSourceMessage: 'No meals found',
          editRow: {
            deleteText: 'Delete meal? This action cannot be undone.',
          },
        },
      }}
      options={{
        actionsColumnIndex: -1,
        exportButton: true,
        paging: false,
        selection: true,
        selectionProps: (rowData) => ({ disabled: !!rowData.isMeal }),
        toolbar: true,
      }}
      actions={[
        {
          action: (rowData) => ({
            disabled: !!rowData.isMeal,
            icon: 'edit',
            onClick: (e, data) => handleLoadUpdateClick(data),
            tooltip: !rowData.isMeal
              ? 'Update meal'
              : 'Cannot edit recipes here',
          }),
          position: 'row',
        },
        {
          action: (rowData) => ({
            disabled: !!rowData.isMeal,
            icon: 'library_add',
            onClick: (e, data) => handleDuplicateMealClick(data),
            tooltip: !rowData.isMeal ? 'Copy meal' : 'Cannot copy recipes here',
          }),
          position: 'row',
        },
        {
          action: (rowData) => ({
            disabled: !!rowData.isMeal,
            icon: rowData.archived ? 'unarchive' : 'archive',
            onClick: (e, data) => handleArchiveClick(data),
            tooltip: !rowData.isMeal
              ? rowData.archived
                ? 'Unarchive meal'
                : 'Archive meal'
              : 'Cannot manage recipes here',
          }),
          position: 'row',
        },
        {
          icon: (data) => (
            <BulkDeleteConfirmationAlert
              data={data}
              name='meal'
              onDelete={handleDeleteManyClick}
            />
          ),
          position: 'toolbarOnSelect',
          tooltip: 'Delete selected meals',
        },
      ]}
      editable={{
        isDeletable: (rowData) => !rowData.isMeal,
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            const newData = [...data]
            const index = oldData.tableData.id
            newData.splice(index, 1)
            setData([...newData])
            onDelete(oldData)
            resolve()
          }),
      }}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MealsTable)
