import {
  ADD_CATEGORY,
  ADD_INGREDIENT,
  ADD_MEAL,
  ADD_PLAN,
  ADD_RECIPE,
  ADD_STORE,
  ADD_USER,
  DELETE_CATEGORY,
  DELETE_CATEGORIES,
  DELETE_INGREDIENT,
  DELETE_INGREDIENTS,
  DELETE_MEAL,
  DELETE_MEALS,
  DELETE_PLAN,
  DELETE_PLANS,
  DELETE_RECIPE,
  DELETE_RECIPES,
  DELETE_STORE,
  DELETE_USER,
  PLAN_LIST_COPIED,
  SET_DEFAULT_STORE,
  SET_STARRED_PLAN,
  SETTINGS_SAVED,
  SNACKBAR_CLOSED,
  UPDATE_CATEGORY,
  UPDATE_INGREDIENT,
  UPDATE_MEAL,
  UPDATE_PLAN,
  UPDATE_RECIPE,
  UPDATE_STORE,
  UPDATE_USER,
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_CATEGORY:
    case ADD_INGREDIENT:
    case ADD_MEAL:
    case ADD_PLAN:
    case ADD_RECIPE:
    case ADD_STORE:
    case ADD_USER:
    case DELETE_CATEGORY:
    case DELETE_CATEGORIES:
    case DELETE_INGREDIENT:
    case DELETE_INGREDIENTS:
    case DELETE_MEAL:
    case DELETE_MEALS:
    case DELETE_PLAN:
    case DELETE_PLANS:
    case DELETE_RECIPE:
    case DELETE_RECIPES:
    case DELETE_STORE:
    case DELETE_USER:
    case PLAN_LIST_COPIED:
    case SET_DEFAULT_STORE:
    case SET_STARRED_PLAN:
    case SETTINGS_SAVED:
    case UPDATE_CATEGORY:
    case UPDATE_INGREDIENT:
    case UPDATE_MEAL:
    case UPDATE_PLAN:
    case UPDATE_RECIPE:
    case UPDATE_STORE:
    case UPDATE_USER:
      return {
        ...state,
        snackbar: action.error
          ? null //{ message: 'An error occurred', variant: 'error' }
          : action.snackbar,
      }
    case SNACKBAR_CLOSED:
      return { ...state, snackbar: null }
    default:
      return state
  }
}
