import React from 'react'
import { IconButton, Tooltip, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  actionButton: {
    color: theme.palette.text.disabled,
  },
}))

const ActionButton = (props) => {
  const classes = useStyles()

  const passedClasses = props.className || ''

  const handleClick = (ev) => {
    ev.stopPropagation()
    props.onClick()
  }

  return (
    <Tooltip arrow enterTouchDelay={0} title={props.action}>
      <IconButton
        aria-label={props.action}
        className={`${classes.actionButton} ${passedClasses}`}
        onClick={handleClick}
        onFocus={(ev) => ev.stopPropagation()}
        size={props.size}
      >
        {props.children}
      </IconButton>
    </Tooltip>
  )
}

export default ActionButton
