import React, { useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'

const StoreSelector = ({ defaultStore, onChange, stores }) => {
  const [store, setStore] = useState(defaultStore ?? '')

  const handleChange = (e) => {
    const store = e.target.value
    onChange(store)
    setStore(store)
  }

  return (
    <FormControl fullWidth>
      <InputLabel id='store-selector-label'>Select a Store</InputLabel>
      <Select
        id='store-selector'
        labelId='store-selector-label'
        onChange={handleChange}
        value={store}
      >
        {!store && <MenuItem key='blank' value=''></MenuItem>}
        {stores.map((store) => (
          <MenuItem key={store._id} value={store._id}>
            {store.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default StoreSelector
