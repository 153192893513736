import {
  ADD_USER,
  ASYNC_START,
  DELETE_USER,
  MANAGE_USERS_PAGE_LOADED,
  MANAGE_USERS_PAGE_UNLOADED,
  USER_DIALOG_UNLOADED,
  UPDATE_USER,
} from '../constants/actionTypes'

const defaultState = {
  errors: null,
  inProgress: false,
  users: [],
}

const sortByLastName = (a, b) => a.lastName.localeCompare(b.lastName)

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_USER:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        users: action.error
          ? state.users
          : [...state.users, action.payload.user].sort((a, b) =>
              sortByLastName(a, b)
            ),
      }
    case ASYNC_START:
      if (action.subtype === ADD_USER || action.subtype === UPDATE_USER) {
        return { ...state, inProgress: true, errors: null }
      }
      break
    case DELETE_USER:
      return {
        ...state,
        users: action.error
          ? state.users
          : state.users.filter(
              (user) => user.username !== action.payload.user.username
            ),
      }
    case MANAGE_USERS_PAGE_LOADED:
      return {
        ...state,
        users: action.payload.users,
      }
    case MANAGE_USERS_PAGE_UNLOADED:
      return defaultState
    case USER_DIALOG_UNLOADED:
      return { ...state, errors: null }
    case UPDATE_USER:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        users: action.error
          ? state.users
          : state.users
              .map((user) =>
                user.username === action.payload.user.username
                  ? action.payload.user
                  : user
              )
              .sort((a, b) => sortByLastName(a, b)),
      }
    default:
      return state
  }

  return state
}
