import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

import FabAdd from '../FabAdd'
import ListErrors from '../ListErrors'
import agent from '../../agent'

import {
  ADD_INGREDIENT,
  INGREDIENT_DIALOG_UNLOADED,
} from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '420px',
    },
  },
}))

const mapStateToProps = (state) => ({
  ...state.ingredients,
  categories: state.categories.categories,
})

const mapDispatchToProps = (dispatch) => ({
  onAdd: (ingredient) =>
    dispatch({
      type: ADD_INGREDIENT,
      payload: agent.Ingredients.add(ingredient),
      snackbar: { message: 'Ingredient added', variant: 'success' },
    }),
  onExit: () => dispatch({ type: INGREDIENT_DIALOG_UNLOADED }),
})

const IngredientDialog = ({
  categories,
  errors,
  hideButton,
  ingredient,
  inProgress,
  isOpen,
  onAdd,
  onClose,
  onExit,
}) => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleExit = () => {
    onExit()
    onClose && onClose()
  }

  const handleSubmitAdd = (values) => {
    onAdd({ ...values })
  }

  useEffect(() => {
    // Close the dialog only if there are no errors
    if (!inProgress && !errors) setOpen(false)
  }, [inProgress])

  useEffect(() => {
    // Allow for higher-order component control over the visibility of the dialog
    setOpen(!!isOpen)
  }, [isOpen])

  return (
    <>
      {!hideButton && <FabAdd item='ingredient' onClick={handleClickOpen} />}

      <Dialog
        classes={{ paper: classes.paper }}
        fullScreen={fullScreen}
        fullWidth
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
        TransitionProps={{ onExit: handleExit }}
      >
        <Formik
          initialValues={{
            category: ingredient?.category || '',
            name: ingredient?.name || '',
            notes: ingredient?.notes || '',
          }}
          validationSchema={Yup.object({
            category: Yup.string().required('Required'),
            name: Yup.string().required('Required'),
            notes: Yup.string(),
          })}
          onSubmit={async (values) => {
            handleSubmitAdd(values)
          }}
        >
          <Form>
            <DialogTitle>Add ingredient</DialogTitle>
            <DialogContent>
              <ListErrors errors={errors} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    autoFocus
                    color='primary'
                    component={TextField}
                    disabled={inProgress}
                    id='name'
                    label='Ingredient Name'
                    name='name'
                    type='text'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    color='primary'
                    component={TextField}
                    disabled={inProgress}
                    id='category'
                    label='Ingredient Category'
                    name='category'
                    type='select'
                    select
                    fullWidth
                  >
                    {categories.map((category) => (
                      <MenuItem key={category._id} value={category._id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    color='primary'
                    component={TextField}
                    disabled={inProgress}
                    id='notes'
                    label='Ingredient Notes'
                    name='notes'
                    type='text'
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={inProgress}
                color='primary'
                type='submit'
                variant='contained'
              >
                {inProgress ? 'Adding...' : 'Add'}
              </Button>
              <Button
                disabled={inProgress}
                onClick={handleClose}
                variant='contained'
              >
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(IngredientDialog)
