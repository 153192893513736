import React, { useEffect, useState } from 'react'

import IngredientSelector from './IngredientSelector'
import MaterialTableResponsive from '../MaterialTableResponsive'

import { UNITS } from '../../constants/units'

const RecipeIngredientsEditor = ({
  ingredients,
  ingredientsList,
  onChange,
}) => {
  const [columns, setColumns] = useState([])
  const [data, setData] = useState([])

  const getColumns = () => [
    {
      title: 'Name',
      field: 'id',
      lookup: ingredientsList.reduce(
        (obj, item) => ((obj[item._id] = item.name), obj),
        {}
      ),
      editComponent: (props) => (
        <IngredientSelector
          onChange={(e, value) => props.onChange(value?._id)}
          value={ingredientsList.find(
            (ingredient) => ingredient._id === props.value
          )}
        />
      ),
    },
    { title: 'Quantity', field: 'quantity' },
    {
      title: 'Unit',
      field: 'unit',
      lookup: UNITS.reduce(
        (obj, item) => ((obj[item.abbr] = `${item.name}s (${item.abbr})`), obj),
        {}
      ),
    },
    {
      title: 'Notes',
      field: 'notes',
    },
  ]

  useEffect(() => {
    // Refresh the columns when adding a new ingredient to update the IngredientSelector component
    setColumns(getColumns())
  }, [ingredientsList])

  useEffect(() => {
    setData(ingredients)
  }, [ingredients])

  return (
    <MaterialTableResponsive
      title='Ingredients'
      columns={columns}
      data={data}
      localization={{
        body: {
          emptyDataSourceMessage: 'No ingredients have been added',
          editRow: {
            deleteText: 'Remove ingredient from this recipe?',
          },
        },
      }}
      options={{ actionsColumnIndex: -1, paging: false, search: false }}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            setData([...data, newData])
            onChange([...data, newData])
            resolve()
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data]
            const index = oldData.tableData.id
            dataUpdate[index] = newData
            setData([...dataUpdate])
            onChange([...dataUpdate])
            resolve()
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            const newData = [...data]
            const index = oldData.tableData.id
            newData.splice(index, 1)
            setData([...newData])
            onChange([...newData])
            resolve()
          }),
      }}
    />
  )
}

export default RecipeIngredientsEditor
