import React, { useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'

import CategoryDroppable from './CategoryDroppable'

const CategorySorter = ({ categories, name, onChange }) => {
  const [list, setList] = useState(categories)

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result

    if (!destination || destination.index === source.index) return

    const category = categories.find(
      (category) => category.slug === draggableId
    )
    const newList = [...list]
    newList.splice(source.index, 1)
    newList.splice(destination.index, 0, category)

    setList(newList)
    onChange(name, newList)
    return
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <CategoryDroppable categories={list} />
    </DragDropContext>
  )
}

export default CategorySorter
