import React from 'react'
import { connect } from 'react-redux'
import { Grid, Paper, Typography, makeStyles } from '@material-ui/core'

import ArchivedIcon from '../ArchivedIcon'
import DefrostIcon from '../DefrostIcon'
import OvernightIcon from '../OvernightIcon'
import PrepTimeIcon from '../PrepTimeIcon'

import { RECIPE_VIEW_LOADED } from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  dayList: {
    listStyle: 'none',
    paddingLeft: theme.spacing(1),
  },
  mealHeader: {
    padding: theme.spacing(1),
  },
  mealLink: {
    'border': '1px solid',
    'borderColor': 'white',
    'borderRadius': theme.spacing(1),
    'padding': theme.spacing(1),
    '&:hover': {
      borderColor: theme.palette.secondary.light,
    },
    '& a': {
      color: theme.palette.text.primary,
      height: '100%',
      textDecoration: 'none',
      width: '100%',
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  recipeList: {
    listStyle: 'none',
    paddingLeft: theme.spacing(2),
  },
  verticalCenter: {
    alignItems: 'center',
    display: 'flex',
  },
}))

const mapStateToProps = (state) => ({
  longDuration: state.common.settings.longDuration,
  meals: state.meals.meals,
  plan: state.plans.viewer.plan,
  recipes: state.recipes.recipes,
})

const mapDispatchToProps = (dispatch) => ({
  onLoadView: (recipe) => dispatch({ type: RECIPE_VIEW_LOADED, recipe }),
})

const PlanViewer = ({ longDuration, meals, onLoadView, plan, recipes }) => {
  const classes = useStyles()

  const days = Object.keys(plan.meals)

  const mealLookup = (id) => {
    const meal = meals.find((meal) => meal._id === id)
    if (meal) return meal

    const recipe = recipes.find((recipe) => recipe._id === id)
    return recipe
  }

  const handleLoadViewClick = (recipe) => {
    onLoadView(recipe)
  }

  return (
    plan && (
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h5'>{plan.title}</Typography>
          </Grid>
          {plan.notes && (
            <Grid item xs={12}>
              <Typography variant='h6'>Notes</Typography>
              <Typography>{plan.notes}</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant='h5'>Meals</Typography>
            <hr />
            <Grid container item xs={12} spacing={2}>
              {days.map((day) => {
                const meals = plan.meals[day]
                return (
                  <Grid item xs={12} key={day}>
                    <Typography variant='h6'>{day}</Typography>
                    <ul className={classes.dayList}>
                      {meals.map((mealId) => {
                        const meal = mealLookup(mealId)
                        return meal?.recipes ? (
                          <li key={mealId}>
                            <Typography
                              className={`${classes.mealHeader} ${classes.verticalCenter}`}
                            >
                              <em>{meal?.title}</em>
                              {meal?.archived && <ArchivedIcon />}
                            </Typography>
                            <ul className={classes.recipeList}>
                              {meal.recipes.map((recipe) => (
                                <li
                                  className={classes.mealLink}
                                  key={recipe._id}
                                >
                                  <a
                                    href='#'
                                    onClick={() => handleLoadViewClick(recipe)}
                                  >
                                    <Typography
                                      className={classes.verticalCenter}
                                    >
                                      {recipe.title}{' '}
                                      {recipe.archived && <ArchivedIcon />}
                                      {recipe.prepTime >= longDuration && (
                                        <PrepTimeIcon />
                                      )}
                                      {recipe.defrost && <DefrostIcon />}
                                      {recipe.overnight && <OvernightIcon />}
                                    </Typography>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ) : (
                          <li className={classes.mealLink} key={meal?._id}>
                            <a
                              href='#'
                              onClick={() => handleLoadViewClick(meal)}
                            >
                              <Typography className={classes.verticalCenter}>
                                {meal?.title || 'Deleted item'}{' '}
                                {meal?.archived && <ArchivedIcon />}
                                {meal?.prepTime >= longDuration && (
                                  <PrepTimeIcon />
                                )}
                                {meal?.defrost && <DefrostIcon />}
                                {meal?.overnight && <OvernightIcon />}
                              </Typography>
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanViewer)
