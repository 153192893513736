import React, { useEffect, useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

const MealSelector = ({ meals, name, onChange }) => {
  const [options, setOptions] = useState([])

  const mappedOptions = meals.map((meal) => {
    const firstLetter = meal.title[0].toUpperCase()
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...meal,
    }
  })

  const handleChange = (ev, value) => {
    onChange(name, value?._id)
  }

  useEffect(() => {
    setOptions(mappedOptions)
  }, [meals])

  return (
    <Autocomplete
      autoHighlight
      autoSelect
      getOptionLabel={(option) => {
        return option.title
      }}
      groupBy={(option) => option.firstLetter}
      noOptionsText='No meals have been created'
      onChange={handleChange}
      options={options}
      renderInput={(params) => (
        <TextField {...params} autoFocus label='Meals' variant='outlined' />
      )}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.title, inputValue)
        const parts = parse(option.title, matches)
        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}
          </div>
        )
      }}
    />
  )
}

export default MealSelector
