import React from 'react'
import { Grid, Link, Typography, makeStyles } from '@material-ui/core'

import profile from '../images/doc-may-profile.jpg'

const useStyles = makeStyles((theme) => ({
  page: {
    '& p:not(:last-of-type)': {
      marginBottom: theme.spacing(2),
    },
  },
  profile: {
    borderRadius: '100%',
    boxShadow: theme.shadows[10],
    float: 'right',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '120px',
  },
}))

const About = () => {
  const classes = useStyles()

  return (
    <Grid className={classes.page} container spacing={2}>
      <Grid item xs={12}>
        <Typography align='center' gutterBottom variant='h5'>
          What is Plate?
        </Typography>
        <img className={classes.profile} src={profile} />
        <Typography variant='body2'>
          Hello, I'm Joseph May, a high school chemistry teacher from Las Vegas.
          While I love teaching chemistry, I also love programming for the web,
          so why not combine the two.
        </Typography>
        <Typography variant='body2'>
          Plate is a web-based app I created to help my wife and I manage our
          meals and shopping list for each week. It allows you to create weekly
          meal plans from recipes you input into the system. It can then
          generate and push a shopping list to your iPhone or Android device.
        </Typography>
        <Typography variant='body2'>
          While this app can support multiple users, I am running it on no-cost
          servers, so it was not intended to be a public program. Although, if
          you are interested in learning more, please reach out to me{' '}
          <Link href='mailto:jwmay87+plate@gmail.com'>here</Link>.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default About
