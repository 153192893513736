import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import ListDialog from './Lists/ListDialog'
import PageHeader from '../PageHeader'
import PlanEditor from './PlanEditor'
import PlanViewer from './PlanViewer'
import PlansTable from './PlansTable'
import RecipeViewer from '../Recipes/RecipeViewer'
import Spinner from '../Spinner'
import agent from '../../agent'

import {
  PLANS_PAGE_LOADED,
  PLANS_PAGE_UNLOADED,
  PLAN_VIEW_UNLOADED,
  RECIPE_VIEW_UNLOADED,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  ingredients: state.ingredients.ingredients,
  pageLoaded: state.plans.pageLoaded,
  planViewer: state.plans.viewer,
  recipeViewer: state.recipes.viewer,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({
      type: PLANS_PAGE_LOADED,
      payload: Promise.all([
        agent.Plans.getAll(),
        agent.Meals.getAll(),
        agent.Recipes.getAll(),
        agent.Ingredients.getAll(),
        agent.Categories.getAll(),
        agent.Stores.getAll(),
      ]),
    }),
  onUnload: () => dispatch({ type: PLANS_PAGE_UNLOADED }),
  onUnloadPlanView: () => dispatch({ type: PLAN_VIEW_UNLOADED }),
  onUnloadRecipeView: () => dispatch({ type: RECIPE_VIEW_UNLOADED }),
})

const Plans = ({
  ingredients,
  onLoad,
  onUnload,
  onUnloadPlanView,
  onUnloadRecipeView,
  pageLoaded,
  planViewer,
  recipeViewer,
}) => {
  const [accordionOpen, setAccordionOpen] = useState(false)

  const showPlanView = planViewer.plan && planViewer.type === 'plan'
  const showRecipeView = !!recipeViewer.recipe

  useEffect(() => {
    onLoad()

    return () => {
      onUnload()
    }
  }, [])

  return (
    <>
      <PageHeader
        title={
          showRecipeView
            ? 'View Recipe'
            : showPlanView
            ? 'View Plan'
            : 'Manage Plans'
        }
      >
        {showRecipeView ? (
          <Button color='secondary' onClick={onUnloadRecipeView}>
            Back to Plan
          </Button>
        ) : showPlanView ? (
          <Button color='secondary' onClick={onUnloadPlanView}>
            Manage Plans
          </Button>
        ) : (
          <PlanEditor />
        )}
      </PageHeader>
      {pageLoaded ? (
        showRecipeView ? (
          <RecipeViewer
            ingredients={ingredients}
            recipe={recipeViewer.recipe}
          />
        ) : showPlanView ? (
          <PlanViewer />
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <PlansTable filter='unarchived' />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h5' gutterBottom>
                Archived Plans
              </Typography>
              <Accordion
                onChange={(ev, expanded) => setAccordionOpen(expanded)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls='archived-content'
                  id='archived-header'
                >
                  <Typography variant='body1'>
                    Click to {accordionOpen ? 'hide' : 'show'} archived plans
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'block' }}>
                  <PlansTable filter='archived' />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        )
      ) : (
        <Spinner />
      )}
      <ListDialog />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Plans)
