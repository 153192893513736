export const APP_LOAD = 'APP_LOAD'
export const REDIRECT = 'REDIRECT'

export const ASYNC_START = 'ASYNC_START'
export const ASYNC_END = 'ASYNC_END'

export const LOGIN = 'LOGIN'
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED'
export const LOGOUT = 'LOGOUT'

export const REGISTER = 'REGISTER'
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED'

export const SETTINGS_PAGE_UNLOADED = 'SETTINGS_PAGE_UNLOADED'
export const SETTINGS_SAVED = 'SETTINGS_SAVED'

export const NAV_LOADED = 'NAV_LOADED'
export const NAV_UNLOADED = 'NAV_UNLOADED'
export const NAV_TAB_CHANGE = 'NAV_TAB_CHANGE'

export const ALERT_CLOSED = 'ALERT_CLOSED'
export const SNACKBAR_CLOSED = 'SNACKBAR_CLOSED'

export const ADD_USER = 'ADD_USER'
export const DELETE_USER = 'DELETE_USER'
export const MANAGE_USERS_PAGE_LOADED = 'MANAGE_USERS_PAGE_LOADED'
export const MANAGE_USERS_PAGE_UNLOADED = 'MANAGE_USERS_PAGE_UNLOADED'
export const USER_DIALOG_UNLOADED = 'USER_DIALOG_UNLOADED'
export const UPDATE_USER = 'UPDATE_USER'

export const ADD_CATEGORY = 'ADD_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const DELETE_CATEGORIES = 'DELETE_CATEGORIES'
export const CATEGORY_DIALOG_UNLOADED = 'CATEGORY_DIALOG_UNLOADED'
export const CATEGORIES_PAGE_LOADED = 'CATEGORIES_PAGE_LOADED'
export const CATEGORIES_PAGE_UNLOADED = 'CATEGORIES_PAGE_UNLOADED'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'

export const ADD_INGREDIENT = 'ADD_INGREDIENT'
export const DELETE_INGREDIENT = 'DELETE_INGREDIENT'
export const DELETE_INGREDIENTS = 'DELETE_INGREDIENTS'
export const INGREDIENT_DIALOG_UNLOADED = 'INGREDIENT_DIALOG_UNLOADED'
export const INGREDIENTS_PAGE_LOADED = 'INGREDIENTS_PAGE_LOADED'
export const INGREDIENTS_PAGE_UNLOADED = 'INGREDIENTS_PAGE_UNLOADED'
export const UPDATE_INGREDIENT = 'UPDATE_INGREDIENT'

export const ADD_RECIPE = 'ADD_RECIPE'
export const DELETE_RECIPE = 'DELETE_RECIPE'
export const DELETE_RECIPES = 'DELETE_RECIPES'
export const RECIPE_DIALOG_LOADED = 'RECIPE_DIALOG_LOADED'
export const RECIPE_DIALOG_UNLOADED = 'RECIPE_DIALOG_UNLOADED'
export const RECIPE_INGREDIENT_ONBLUR = 'RECIPE_INGREDIENT_ONBLUR'
export const RECIPE_VIEW_LOADED = 'RECIPE_VIEW_LOADED'
export const RECIPE_VIEW_UNLOADED = 'RECIPE_VIEW_UNLOADED'
export const RECIPES_PAGE_LOADED = 'RECIPES_PAGE_LOADED'
export const RECIPES_PAGE_UNLOADED = 'RECIPES_PAGE_UNLOADED'
export const UPDATE_RECIPE = 'UPDATE_RECIPE'

export const ADD_MEAL = 'ADD_MEAL'
export const DELETE_MEAL = 'DELETE_MEAL'
export const DELETE_MEALS = 'DELETE_MEALS'
export const MEAL_DIALOG_LOADED = 'MEAL_DIALOG_LOADED'
export const MEAL_DIALOG_UNLOADED = 'MEAL_DIALOG_UNLOADED'
export const MEALS_PAGE_LOADED = 'MEALS_PAGE_LOADED'
export const MEALS_PAGE_UNLOADED = 'MEALS_PAGE_UNLOADED'
export const UPDATE_MEAL = 'UPDATE_MEAL'

export const ADD_PLAN = 'ADD_PLAN'
export const DELETE_PLAN = 'DELETE_PLAN'
export const DELETE_PLANS = 'DELETE_PLANS'
export const PLAN_DIALOG_LOADED = 'PLAN_DIALOG_LOADED'
export const PLAN_DIALOG_UNLOADED = 'PLAN_DIALOG_UNLOADED'
export const PLAN_LIST_COPIED = 'PLAN_LIST_COPIED'
export const PLAN_LIST_DIALOG_LOADED = 'PLAN_LIST_DIALOG_LOADED'
export const PLAN_LIST_DIALOG_UNLOADED = 'PLAN_LIST_DIALOG_UNLOADED'
export const PLAN_VIEW_LOADED = 'PLAN_VIEW_LOADED'
export const PLAN_VIEW_UNLOADED = 'PLAN_VIEW_UNLOADED'
export const PLANS_PAGE_LOADED = 'PLANS_PAGE_LOADED'
export const PLANS_PAGE_UNLOADED = 'PLANS_PAGE_UNLOADED'
export const SET_STARRED_PLAN = 'SET_STARRED_PLAN'
export const UPDATE_PLAN = 'UPDATE_PLAN'

export const ADD_STORE = 'ADD_STORE'
export const DELETE_STORE = 'DELETE_STORE'
export const SET_DEFAULT_STORE = 'SET_DEFAULT_STORE'
export const STORE_DIALOG_LOADED = 'STORE_DIALOG_LOADED'
export const STORE_DIALOG_UNLOADED = 'STORE_DIALOG_UNLOADED'
export const STORES_PAGE_LOADED = 'STORES_PAGE_LOADED'
export const STORES_PAGE_UNLOADED = 'STORES_PAGE_UNLOADED'
export const UPDATE_STORE = 'UPDATE_STORE'
