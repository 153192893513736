import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { MTableAction } from 'material-table'

// @todo remove fix
// import MaterialTable from '@material-table/core'
import MaterialTableFix from '../MaterialTableFix'
import BulkDeleteConfirmationAlert from '../BulkDeleteConfirmationAlert'

import agent from '../../agent'

import {
  DELETE_CATEGORY,
  DELETE_CATEGORIES,
  UPDATE_CATEGORY,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  ...state.categories,
})

const mapDispatchToProps = (dispatch) => ({
  onDelete: (category) =>
    dispatch({
      type: DELETE_CATEGORY,
      payload: agent.Categories.delete(category),
      snackbar: { message: 'Category deleted', variant: 'success' },
    }),
  onDeleteMany: (slugs) =>
    dispatch({
      type: DELETE_CATEGORIES,
      payload: agent.Categories.deleteMany(slugs),
      snackbar: {
        message: `${slugs.length} categor${
          slugs.length > 1 ? 'ies' : 'y'
        } deleted`,
        variant: 'success',
      },
    }),
  onUpdate: (category) =>
    dispatch({
      type: UPDATE_CATEGORY,
      payload: agent.Categories.update(category),
      snackbar: { message: 'Category updated', variant: 'success' },
    }),
})

const CategoriesTable = ({ categories, onDelete, onDeleteMany, onUpdate }) => {
  const [data, setData] = useState([])

  const [columns] = useState([
    { title: 'Name', field: 'name' },
    { title: 'Notes', field: 'notes' },
  ])

  const handleDeleteManyClick = (data) => {
    const slugs = data.map((row) => row.slug)
    onDeleteMany(slugs)
  }

  useEffect(() => {
    setData(categories)
  }, [categories])

  return (
    <MaterialTableFix
      title='Categories'
      columns={columns}
      data={data}
      components={{
        Action: (props) => {
          if (typeof props.action.icon === 'function') {
            return props.action.icon(props.data)
          }
          return <MTableAction {...props} />
        },
      }}
      localization={{
        body: {
          emptyDataSourceMessage: 'No categories found',
          editRow: {
            deleteText: 'Delete category? This action cannot be undone.',
          },
        },
      }}
      options={{
        actionsColumnIndex: -1,
        paging: false,
        selection: true,
        toolbar: true,
      }}
      actions={[
        {
          icon: (data) => (
            <BulkDeleteConfirmationAlert
              data={data}
              name='categorie'
              onDelete={handleDeleteManyClick}
            />
          ),
          position: 'toolbarOnSelect',
          tooltip: 'Delete selected categories',
        },
      ]}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data]
            const index = oldData.tableData.id
            dataUpdate[index] = newData
            setData([...dataUpdate])
            onUpdate(newData)
            resolve()
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            const newData = [...data]
            const index = oldData.tableData.id
            newData.splice(index, 1)
            setData([...newData])
            onDelete(oldData)
            resolve()
          }),
      }}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesTable)
