import {
  ASYNC_START,
  SETTINGS_PAGE_UNLOADED,
  SETTINGS_SAVED,
} from '../constants/actionTypes'

const defaultState = {
  errors: null,
  inProgress: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case ASYNC_START:
      if (action.subtype === SETTINGS_SAVED) {
        return {
          ...state,
          inProgress: true,
        }
      }
      break
    case SETTINGS_PAGE_UNLOADED:
      return defaultState
    case SETTINGS_SAVED:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.errors : null,
      }
    default:
      return state
  }

  return state
}
