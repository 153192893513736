import { createTheme } from '@material-ui/core'
import { blueGrey, indigo } from '@material-ui/core/colors'

const Theme = createTheme({
  breakpoints: {
    values: {
      md: 768,
      lg: 1024,
    },
  },
  palette: {
    primary: { main: blueGrey[400] },
    secondary: { main: indigo[200] },
  },
  overrides: {
    MuiDialogActions: {
      root: {
        padding: '16px 24px',
      },
    },
    MUIRichTextEditor: {
      placeHolder: {
        position: 'relative',
      },
      toolbar: {
        '& button.MuiIconButton-colorPrimary': {
          color: indigo[200],
        },
      },
      editor: {
        '& li': {
          borderBottom: `1px solid #ccc`,
          marginBottom: '32px',
          paddingBottom: '16px',
        },
      },
    },
  },
})

export default Theme
