import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field, useFormikContext } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core'

import FabAdd from '../FabAdd'
import ListErrors from '../ListErrors'
import RecipeIngredientsEditor from './RecipeIngredientsEditor'
import RichEditor from '../RichEditor'

import {
  ADD_RECIPE,
  RECIPE_DIALOG_UNLOADED,
  UPDATE_RECIPE,
} from '../../constants/actionTypes'
import { MEAL_TYPES } from '../../constants/mealTypes'
import agent from '../../agent'

const mapStateToProps = (state) => ({
  ...state.recipes,
  ingredients: state.ingredients.ingredients,
})

const mapDispatchToProps = (dispatch) => ({
  onAdd: (recipe) =>
    dispatch({
      type: ADD_RECIPE,
      payload: agent.Recipes.add(recipe),
      snackbar: { message: 'Recipe added', variant: 'success' },
    }),
  onExit: () => dispatch({ type: RECIPE_DIALOG_UNLOADED }),
  onUpdate: (recipe) =>
    dispatch({
      type: UPDATE_RECIPE,
      payload: agent.Recipes.update(recipe),
      snackbar: { message: 'Recipe updated', variant: 'success' },
    }),
})

const HandleIsMealChange = () => {
  const { values, setFieldValue } = useFormikContext()
  useEffect(() => {
    if (!values.isMeal) setFieldValue('mealType', '')
  }, [values])
  return null
}

const RecipeEditor = ({
  editor,
  errors,
  ingredients,
  inProgress,
  onAdd,
  onExit,
  onUpdate,
}) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmitAdd = (values) => {
    onAdd({ ...values })
  }

  const handleSubmitUpdate = (values) => {
    onUpdate({ ...editor.recipe, ...values })
  }

  useEffect(() => {
    // Close the dialog only if there are no errors
    if (!inProgress && !errors) setOpen(false)
  }, [inProgress])

  useEffect(() => {
    // Open the dialog if an editor.recipe object is defined
    setOpen(!!editor.recipe)
  }, [editor.recipe])

  return (
    <>
      <FabAdd item='recipe' onClick={handleClickOpen} />

      <Dialog
        fullScreen
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
        TransitionProps={{
          onExit: onExit,
        }}
      >
        <Formik
          initialValues={{
            defrost: editor.recipe?.defrost || false,
            directions: editor.recipe?.directions || '',
            ingredients: editor.recipe?.ingredients || [],
            isMeal: editor.recipe?.isMeal || false,
            mealType: editor.recipe?.mealType || '',
            notes: editor.recipe?.notes || '',
            overnight: editor.recipe?.overnight || false,
            prepTime: editor.recipe?.prepTime || '',
            servings: editor.recipe?.servings || '',
            title:
              !editor.recipe?._id && editor.recipe?.title
                ? `Copy of ${editor.recipe?.title}`
                : editor.recipe?.title || '',
            wwPoints: editor.recipe?.wwPoints || '',
          }}
          validationSchema={Yup.object({
            defrost: Yup.boolean(),
            directions: Yup.string().required('Required'),
            ingredients: Yup.array(),
            isMeal: Yup.boolean(),
            mealType: Yup.string(),
            notes: Yup.string(),
            overnight: Yup.boolean(),
            prepTime: Yup.number().required('Required'),
            servings: Yup.number().required('Required'),
            title: Yup.string().required('Required'),
            wwPoints: Yup.number(),
          })}
          onSubmit={async (values) => {
            editor.recipe?._id
              ? handleSubmitUpdate(values)
              : handleSubmitAdd(values)
          }}
        >
          {(formik) => (
            <Container disableGutters>
              <Form>
                <DialogTitle>
                  {editor.recipe?._id ? 'Update recipe' : 'Add recipe'}
                </DialogTitle>
                <DialogContent>
                  <ListErrors errors={errors} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Field
                        autoFocus={!editor.recipe}
                        component={TextField}
                        disabled={inProgress}
                        id='title'
                        label='Recipe Title'
                        name='title'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Field
                        component={CheckboxWithLabel}
                        disabled={inProgress}
                        type='checkbox'
                        name='isMeal'
                        Label={{ label: 'This recipe is a meal' }}
                      />
                      <HandleIsMealChange />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      {formik.values.isMeal && (
                        <Field
                          component={TextField}
                          disabled={inProgress}
                          id='mealType'
                          label='Meal Type'
                          name='mealType'
                          type='select'
                          select
                          fullWidth
                        >
                          {MEAL_TYPES.map((name) => {
                            const value = name.toLowerCase()
                            return (
                              <MenuItem key={value} value={value}>
                                {name}
                              </MenuItem>
                            )
                          })}
                        </Field>
                      )}
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field
                        component={TextField}
                        disabled={inProgress}
                        id='servings'
                        label='Servings'
                        name='servings'
                        type='number'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field
                        component={TextField}
                        disabled={inProgress}
                        id='prepTime'
                        label='Prep Time (minutes)'
                        name='prepTime'
                        type='number'
                        fullWidth
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={3}>
                      <Field
                        component={TextField}
                        disabled={inProgress}
                        id='wwPoints'
                        label='WW Points'
                        name='wwPoints'
                        type='number'
                        fullWidth
                      />
                    </Grid> */}
                    <Grid item xs={12} md={3}>
                      <Field
                        component={CheckboxWithLabel}
                        disabled={inProgress}
                        type='checkbox'
                        name='defrost'
                        Label={{ label: 'Requires defrost' }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field
                        component={CheckboxWithLabel}
                        disabled={inProgress}
                        type='checkbox'
                        name='overnight'
                        Label={{ label: 'Requires overnight' }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        disabled={inProgress}
                        id='notes'
                        label='Notes'
                        name='notes'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RecipeIngredientsEditor
                        ingredients={formik.values.ingredients}
                        ingredientsList={ingredients}
                        onChange={(ingredients) => {
                          formik.setFieldValue('ingredients', ingredients)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='h6'>Directions</Typography>
                      <RichEditor
                        errors={formik.errors.directions}
                        initialValue={editor.recipe?.directions}
                        name='directions'
                        onEditorBlur={formik.setFieldTouched}
                        onEditorChange={formik.setFieldValue}
                        // onErrorChange={formik.setFieldError} // @todo: setFieldError is overridden by some internal formik function, so errors are handled by the component
                        placeholder='Enter recipe directions here...'
                        touched={formik.touched.directions}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    color='primary'
                    disabled={inProgress}
                    type='submit'
                    variant='contained'
                  >
                    {editor.recipe?._id
                      ? inProgress
                        ? 'Updating...'
                        : 'Update'
                      : inProgress
                      ? 'Adding...'
                      : 'Add'}
                  </Button>
                  <Button
                    disabled={inProgress}
                    onClick={handleClose}
                    variant='contained'
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Form>
            </Container>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipeEditor)
