import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { useMediaQuery, useTheme } from '@material-ui/core'

import CategoryDraggable from './CategoryDraggable'

const CategoryDroppable = ({ categories }) => {
  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('md'))

  const getStyle = (isDraggingOver) => ({
    border: isDraggingOver
      ? `2px dashed ${theme.palette.primary.light}`
      : '2px solid white',
    minHeight: '120px',
    padding: theme.spacing(1),
    width: fullWidth ? '100%' : '50%',
  })

  return (
    <Droppable droppableId='categories'>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={getStyle(snapshot.isDraggingOver)}
        >
          {categories.map((category, index) => (
            <CategoryDraggable
              category={category}
              index={index}
              key={category.slug}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default CategoryDroppable
