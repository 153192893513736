import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Container } from '@material-ui/core'

import Categories from './Categories/Categories'
import Header from './Header'
import Ingredients from './Ingredients/Ingredients'
import ManageUsers from './Admin/ManageUsers'
import Meals from './Meals/Meals'
import Navigation from './Navigation'
import NotFound from './NotFound'
import PageWrapper from './PageWrapper'
import Plans from './Plans/Plans'
import ProtectedRoute from './ProtectedRoute'
import Recipes from './Recipes/Recipes'
import Settings from './Settings'

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
})

const Home = ({ currentUser }) => {
  if (!currentUser) return <Redirect to='/login' />

  return (
    <>
      <Header currentUser={currentUser} />
      <PageWrapper>
        <Container>
          <Switch>
            <ProtectedRoute component={Plans} path='/plans' />
            <ProtectedRoute component={Meals} path='/meals' />
            <ProtectedRoute component={Recipes} path='/recipes' />

            <ProtectedRoute
              component={Categories}
              path='/ingredients/categories'
            />
            <ProtectedRoute component={Ingredients} path='/ingredients' />

            <ProtectedRoute component={Settings} path='/settings' />
            <ProtectedRoute component={ManageUsers} path='/users' />

            <Route component={NotFound} />
          </Switch>
        </Container>
        <Navigation />
      </PageWrapper>
    </>
  )
}

export default connect(mapStateToProps, null)(Home)
