import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import {
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

import MealDraggable from './MealDraggable'
import MealSelectorDialog from './MealSelectorDialog'

const useStyles = makeStyles((theme) => ({
  dayHeader: {
    'alignItems': 'center',
    'borderBottom': `2px solid ${theme.palette.secondary.light}`,
    'display': 'flex',
    'justifyContent': 'space-between',
    'padding': `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(
      1
    )}px`,
    'width': '100%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
    '& > button': {
      visibility: 'hidden',
    },
    '&:hover > button': {
      visibility: 'visible',
    },
  },
}))

const WeekdayDroppable = ({ day, mealPlan, meals, onAdd, onRemove }) => {
  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  const getStyle = (isDraggingOver) => ({
    border: isDraggingOver
      ? `2px dashed ${theme.palette.primary.light}`
      : '2px dashed white',
    minHeight: '120px',
    padding: theme.spacing(1),
    width: fullWidth ? '100%' : '50%',
  })

  const mappedMealPlan = mealPlan.map((mealId) =>
    meals.find((meal) => meal._id === mealId)
  )

  return (
    <div>
      <div className={classes.dayHeader}>
        <Typography color='primary' variant='h6'>
          <em>{day}</em>
        </Typography>
        <MealSelectorDialog
          day={day}
          meals={meals.filter((meal) => !meal.archived)}
          onAdd={onAdd}
        />
      </div>
      <Droppable droppableId={day}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getStyle(snapshot.isDraggingOver)}
          >
            {mappedMealPlan.map((meal, index) => {
              const uniqueId = `${day}-${meal?._id}-${index}`
              return (
                <MealDraggable
                  index={index}
                  key={uniqueId}
                  meal={meal}
                  onRemove={onRemove}
                  uniqueId={uniqueId}
                />
              )
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

export default WeekdayDroppable
