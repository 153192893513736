import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, makeStyles } from '@material-ui/core'

import CategoryDialog from './CategoryDialog'
import CategoriesTable from './CategoriesTable'
import PageHeader from '../PageHeader'
import Spinner from '../Spinner'
import agent from '../../agent'

import {
  CATEGORIES_PAGE_LOADED,
  CATEGORIES_PAGE_UNLOADED,
} from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  button: {
    'marginRight': theme.spacing(2),
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
}))

const mapStateToProps = (state) => ({
  ...state.categories,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({
      type: CATEGORIES_PAGE_LOADED,
      payload: agent.Categories.getAll(),
    }),
  onUnload: () => dispatch({ type: CATEGORIES_PAGE_UNLOADED }),
})

const Categories = ({ onLoad, onUnload, pageLoaded }) => {
  const classes = useStyles()

  useEffect(() => {
    onLoad()

    return () => {
      onUnload()
    }
  }, [])

  return (
    <>
      <PageHeader title='Manage Categories'>
        <Button className={classes.button} color='secondary'>
          <Link to='/ingredients'>Manage ingredients</Link>
        </Button>
        <CategoryDialog />
      </PageHeader>
      {pageLoaded ? <CategoriesTable /> : <Spinner />}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Categories)
