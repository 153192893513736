const UNIT_TYPES = [
  ['count', 'ct', 0],
  ['cup', 'c', 0],
  ['dash', 'dsh', 0],
  ['fluid ounce', 'fl oz', 0],
  ['gram', 'g', 0],
  ['milliliter', 'mL', 0],
  ['ounce', 'oz', 0],
  ['package', 'pkg', 0],
  ['piece', 'piece', 0],
  ['pinch', 'pnch', 0],
  ['pint', 'pt', 0],
  ['pound', 'lb', 0],
  ['quart', 'qt', 0],
  ['slice', 'slc', 0],
  ['spray', 'spray', 0],
  ['tablespoon', 'tbsp', 0],
  ['teaspoon', 'tsp', 0],
]

const MakeUnit = (name, abbr, conv) => ({ name, abbr, conv })

const getUnits = () => UNIT_TYPES.map((unit) => MakeUnit(...unit))

export const UNITS = getUnits()
