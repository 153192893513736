import React from 'react'
import { Tooltip } from '@material-ui/core'
import { Hotel } from '@material-ui/icons'

const OvernightIcon = (props) => {
  return (
    <Tooltip title='requires overnight' arrow>
      <Hotel
        color='primary'
        size='small'
        style={{ marginLeft: '8px' }}
        {...props}
      />
    </Tooltip>
  )
}

export default OvernightIcon
