import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'

import CategorySorter from './CategorySorter/CategorySorter'
import ListErrors from '../ListErrors'

import {
  ADD_STORE,
  STORE_DIALOG_UNLOADED,
  UPDATE_STORE,
} from '../../constants/actionTypes'
import agent from '../../agent'

const mapStateToProps = (state) => ({
  ...state.stores,
  categories: state.categories.categories,
})

const mapDispatchToProps = (dispatch) => ({
  onAdd: (store) =>
    dispatch({
      type: ADD_STORE,
      payload: agent.Stores.add(store),
      snackbar: { message: 'Store added', variant: 'success' },
    }),
  onExit: () => dispatch({ type: STORE_DIALOG_UNLOADED }),
  onUpdate: (store) =>
    dispatch({
      type: UPDATE_STORE,
      payload: agent.Stores.update(store),
      snackbar: { message: 'Store updated', variant: 'success' },
    }),
})

const StoreDialog = ({
  categories,
  editor,
  errors,
  inProgress,
  onAdd,
  onExit,
  onUpdate,
}) => {
  const [open, setOpen] = useState(false)

  const isUpdate = !!editor.store?._id

  const getCategories = () => {
    if (!editor.store?.categories) return categories

    let allCategories = [...editor.store.categories]
    categories.forEach((category) => {
      if (!allCategories.find((cat) => cat._id === category._id)) {
        allCategories.push({ ...category, isNew: true })
      }
    })
    return allCategories
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmitAdd = (values) => {
    onAdd({ ...values })
  }

  const handleSubmitUpdate = (values) => {
    onUpdate({ ...editor.store, ...values })
  }

  useEffect(() => {
    // Close the dialog only if there are no errors
    if (!inProgress && !errors) setOpen(false)
  }, [inProgress])

  useEffect(() => {
    // Open the dialog if an editor.store object is defined
    setOpen(!!editor.store)
  }, [editor.store])

  return (
    <Dialog
      fullScreen
      onClick={(ev) => ev.stopPropagation()}
      onClose={handleClose}
      onFocus={(ev) => ev.stopPropagation()}
      open={open}
      TransitionProps={{
        onExit: onExit,
      }}
    >
      <Formik
        initialValues={{
          categories: getCategories(),
          name:
            !editor.store?._id && editor.store?.name
              ? `Copy of ${editor.store?.name}`
              : editor.store?.name || '',
          notes: editor.store?.notes || '',
        }}
        validationSchema={Yup.object({
          categories: Yup.array(),
          name: Yup.string().required('Required'),
          notes: Yup.string(),
        })}
        onSubmit={async (values) => {
          isUpdate ? handleSubmitUpdate(values) : handleSubmitAdd(values)
        }}
      >
        {(formik) => (
          <Container disableGutters>
            <Form>
              <DialogTitle>
                {isUpdate ? 'Update store' : 'Add store'}
              </DialogTitle>
              <DialogContent>
                <ListErrors errors={errors} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      autoFocus={!isUpdate}
                      component={TextField}
                      disabled={inProgress}
                      id='name'
                      label='Store Name'
                      name='name'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      disabled={inProgress}
                      id='notes'
                      label='Store Notes'
                      name='notes'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>Categories</Typography>
                    <CategorySorter
                      categories={formik.values.categories}
                      name='categories'
                      onChange={formik.setFieldValue}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  disabled={inProgress}
                  type='submit'
                  variant='contained'
                >
                  {isUpdate
                    ? inProgress
                      ? 'Updating...'
                      : 'Update'
                    : inProgress
                    ? 'Adding...'
                    : 'Add'}
                </Button>
                <Button
                  disabled={inProgress}
                  onClick={handleClose}
                  variant='contained'
                >
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          </Container>
        )}
      </Formik>
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreDialog)
