import React from 'react'
import { connect } from 'react-redux'
import { Grid, Paper, Typography, makeStyles } from '@material-ui/core'

import ArchivedIcon from '../ArchivedIcon'
import DefrostIcon from '../DefrostIcon'
import OvernightIcon from '../OvernightIcon'
import PrepTimeIcon from '../PrepTimeIcon'
import RichEditor from '../RichEditor'

const useStyles = makeStyles((theme) => ({
  ingredients: {
    backgroundColor: '#eee',
  },
  ingredientsList: {
    'listStyle': 'none',
    'paddingLeft': theme.spacing(2),
    '& li': {
      marginBottom: theme.spacing(2),
    },
  },
  ingredientName: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: 'bold',
  },
  ingredientDetails: {
    color: theme.palette.text.secondary,
  },
  paper: {
    padding: theme.spacing(2),
  },
  verticalCenter: {
    alignItems: 'center',
    display: 'flex',
  },
}))

const mapStateToProps = (state) => ({
  longDuration: state.common.settings.longDuration,
})

const RecipeViewer = ({ ingredients, longDuration, recipe }) => {
  const classes = useStyles()

  const ingredientLookup = (id) => {
    return ingredients.find((ingredient) => ingredient._id === id)
  }

  const getRecipeSubheader = (recipe) => {
    const parts = []
    if (recipe.servings)
      parts.push(
        <React.Fragment key={1}>
          <em>Servings: </em>
          <u>{recipe.servings}</u>
        </React.Fragment>
      )
    parts.push(' | ')
    if (recipe.prepTime)
      parts.push(
        <React.Fragment key={2}>
          <em>Prep Time: </em>
          <u>{recipe.prepTime}</u>
        </React.Fragment>
      )
    if (recipe.wwPoints) {
      parts.push(' | ')
      parts.push(
        <React.Fragment key={3}>
          <em>WW Points: </em>
          <u>{recipe.wwPoints}</u>
        </React.Fragment>
      )
    }
    if (recipe.isMeal) {
      parts.push(' | ')
      parts.push(
        <React.Fragment key={4}>
          <em>Meal Type: </em>
          <u>{recipe.mealType}</u>
        </React.Fragment>
      )
    }
    return parts
  }

  return (
    recipe && (
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h5' className={classes.verticalCenter}>
              {recipe.title} {recipe.archived && <ArchivedIcon />}{' '}
              {recipe.prepTime >= longDuration && <PrepTimeIcon />}{' '}
              {recipe.defrost && <DefrostIcon />}{' '}
              {recipe.overnight && <OvernightIcon />}
            </Typography>
            <Typography variant='subtitle2'>
              {getRecipeSubheader(recipe)}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.ingredients}>
            <Typography variant='h6'>Ingredients</Typography>
            <ul className={classes.ingredientsList}>
              {recipe.ingredients.map((item) => {
                const ingredient = ingredientLookup(item.id)
                if (!item.id) return null
                return (
                  <li key={item.id}>
                    <span className={classes.ingredientName}>
                      {ingredient?.name}
                    </span>
                    <br />
                    <span className={classes.ingredientDetails}>
                      {`${item.quantity || ''} ${item.unit || ''}`}
                      {item.notes && `, ${item.notes}`}
                    </span>
                  </li>
                )
              })}
            </ul>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>Directions</Typography>
            <RichEditor
              initialValue={recipe?.directions}
              toolbar={false}
              readOnly
            />
          </Grid>
          {recipe.notes && (
            <Grid item xs={12}>
              <Typography variant='h6'>Notes</Typography>
              <Typography>{recipe.notes}</Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    )
  )
}

export default connect(mapStateToProps, () => ({}))(RecipeViewer)
