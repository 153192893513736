import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { MTableAction } from 'material-table'

// @todo remove fix
// import MaterialTable from '@material-table/core'
import MaterialTableFix from '../MaterialTableFix'
import BulkDeleteConfirmationAlert from '../BulkDeleteConfirmationAlert'

import agent from '../../agent'

import {
  DELETE_INGREDIENT,
  DELETE_INGREDIENTS,
  UPDATE_INGREDIENT,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  ingredients: state.ingredients.ingredients,
})

const mapDispatchToProps = (dispatch) => ({
  onDelete: (ingredient) =>
    dispatch({
      type: DELETE_INGREDIENT,
      payload: agent.Ingredients.delete(ingredient),
      snackbar: { message: 'Ingredient deleted', variant: 'success' },
    }),
  onDeleteMany: (slugs) =>
    dispatch({
      type: DELETE_INGREDIENTS,
      payload: agent.Ingredients.deleteMany(slugs),
      snackbar: {
        message: `${slugs.length} ingredient${
          slugs.length > 1 ? 's' : ''
        } deleted`,
        variant: 'success',
      },
    }),
  onUpdate: (ingredient) =>
    dispatch({
      type: UPDATE_INGREDIENT,
      payload: agent.Ingredients.update(ingredient),
      snackbar: { message: 'Ingredient updated', variant: 'success' },
    }),
})

const IngredientsTable = ({
  categories,
  ingredients,
  onDelete,
  onDeleteMany,
  onUpdate,
}) => {
  const [data, setData] = useState([])

  const [columns] = useState([
    { title: 'Name', field: 'name' },
    {
      title: 'Category',
      field: 'category',
      lookup: categories.reduce(
        (obj, item) => ((obj[item._id] = item.name), obj),
        {}
      ),
    },
    { title: 'Notes', field: 'notes' },
  ])

  const handleDeleteManyClick = (data) => {
    const slugs = data.map((row) => row.slug)
    onDeleteMany(slugs)
  }

  useEffect(() => {
    setData(ingredients)
  }, [ingredients])

  return (
    <MaterialTableFix
      title='Ingredients'
      columns={columns}
      data={data}
      components={{
        Action: (props) => {
          if (typeof props.action.icon === 'function') {
            return props.action.icon(props.data)
          }
          return <MTableAction {...props} />
        },
      }}
      localization={{
        body: {
          emptyDataSourceMessage: 'No ingredients found',
          editRow: {
            deleteText: 'Delete ingredient? This action cannot be undone.',
          },
        },
      }}
      options={{
        actionsColumnIndex: -1,
        paging: false,
        selection: true,
        toolbar: true,
      }}
      actions={[
        {
          icon: (data) => (
            <BulkDeleteConfirmationAlert
              data={data}
              name='ingredient'
              onDelete={handleDeleteManyClick}
            />
          ),
          position: 'toolbarOnSelect',
          tooltip: 'Delete selected ingredients',
        },
      ]}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const dataUpdate = [...data]
            const index = oldData.tableData.id
            dataUpdate[index] = newData
            setData([...dataUpdate])
            onUpdate(newData)
            resolve()
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            const newData = [...data]
            const index = oldData.tableData.id
            newData.splice(index, 1)
            setData([...newData])
            onDelete(oldData)
            resolve()
          }),
      }}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(IngredientsTable)
