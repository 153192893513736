import React from 'react'
import { connect } from 'react-redux'
import {
  AppBar,
  Button,
  Container,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core'

import Logo from '../components/Logo'

import { LOGOUT } from '../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'white',
    flexGrow: 1,
  },
  logo: {
    height: 65,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}))

const mapStateToProps = (state) => ({
  navTabValue: state.nav.navTabValue,
})

const mapDispatchToProps = (dispatch) => ({
  onClickLogout: () => dispatch({ type: LOGOUT }),
})

const Header = (props) => {
  const classes = useStyles()
  const title = props.navTabValue

  if (props.currentUser) {
    return (
      <AppBar position='static'>
        <Container>
          <Toolbar>
            <Logo className={classes.logo} />
            <Typography variant='h5' align='center' className={classes.title}>
              {title && title.charAt(0).toUpperCase() + title.slice(1)}
            </Typography>
            <Button color='inherit' onClick={props.onClickLogout}>
              Logout
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
    )
  } else {
    return null
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
