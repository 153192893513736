import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MaterialTable, { MTableAction } from '@material-table/core'
import { Paper } from '@material-ui/core'
import { StarBorder } from '@material-ui/icons'

import BulkDeleteConfirmationAlert from '../BulkDeleteConfirmationAlert'

import {
  DELETE_PLAN,
  DELETE_PLANS,
  PLAN_DIALOG_LOADED,
  PLAN_LIST_DIALOG_LOADED,
  PLAN_VIEW_LOADED,
  SET_STARRED_PLAN,
  UPDATE_PLAN,
} from '../../constants/actionTypes'
import agent from '../../agent'

const mapStateToProps = (state) => ({
  plans: state.plans.plans,
  starredPlan: state.common.settings.starredPlan,
})

const mapDispatchToProps = (dispatch) => ({
  onDelete: (plan) =>
    dispatch({
      type: DELETE_PLAN,
      payload: agent.Plans.delete(plan),
      snackbar: { message: 'Plan deleted', variant: 'success' },
    }),
  onDeleteMany: (slugs) =>
    dispatch({
      type: DELETE_PLANS,
      payload: agent.Plans.deleteMany(slugs),
      snackbar: {
        message: `${slugs.length} plan${slugs.length > 1 ? 's' : ''} deleted`,
        variant: 'success',
      },
    }),
  onLoadUpdate: (plan) => dispatch({ type: PLAN_DIALOG_LOADED, plan }),
  onLoadView: (plan) => dispatch({ type: PLAN_VIEW_LOADED, plan }),
  onSetStarred: (settings) =>
    dispatch({
      type: SET_STARRED_PLAN,
      payload: agent.Settings.update(settings),
      snackbar: { message: 'Starred plan saved', variant: 'success' },
    }),
  onUpdateArchiveStatus: (plan, status) =>
    dispatch({
      type: UPDATE_PLAN,
      payload: agent.Plans.update(plan),
      snackbar: { message: `Plan ${status}`, variant: 'success' },
    }),
  onViewShoppingList: (plan) =>
    dispatch({ type: PLAN_LIST_DIALOG_LOADED, plan }),
})

const PlansTable = ({
  filter,
  onDelete,
  onDeleteMany,
  onLoadUpdate,
  onLoadView,
  onSetStarred,
  onUpdateArchiveStatus,
  onViewShoppingList,
  plans,
  starredPlan,
}) => {
  const [data, setData] = useState([])

  const [columns] = useState([
    { title: 'Title', field: 'title' },
    { title: 'Notes', field: 'notes' },
  ])

  const handleArchiveClick = (plan) => {
    const status = plan.archived ? 'unarchived' : 'archived'
    onUpdateArchiveStatus({ ...plan, archived: !plan.archived }, status)
  }

  const handleDeleteManyClick = (data) => {
    const slugs = data.map((row) => row.slug)
    onDeleteMany(slugs)
  }

  const handleDuplicatePlanClick = (plan) => {
    plan._id = ''
    onLoadUpdate(plan)
  }

  const handleLoadUpdateClick = (plan) => {
    onLoadUpdate(plan)
  }

  const handleLoadViewClick = (plan) => {
    onLoadView(plan)
  }

  const handleSetStarredClick = (plan) => {
    const settings = { starredPlan: starredPlan !== plan._id ? plan : null }
    onSetStarred(settings)
  }

  const handleViewShoppingListClick = (plan) => {
    onViewShoppingList(plan)
  }

  useEffect(() => {
    // Put starred plan at top of table
    const sortedPlans = [...plans].sort((a, b) =>
      a._id === starredPlan ? -1 : b._id === starredPlan ? 1 : 0
    )

    // Filter plans based on archive status, or show all plans if no filter given
    //  * allowed options include 'archived' and 'unarchived'
    const filteredPlans =
      filter === 'archived'
        ? sortedPlans.filter((plan) => plan.archived === true)
        : filter === 'unarchived'
        ? sortedPlans.filter((plan) => plan.archived === false)
        : sortedPlans

    setData(filteredPlans)
  }, [plans, starredPlan])

  return (
    <MaterialTable
      title='Plans'
      columns={columns}
      data={data}
      components={{
        Action: (props) => {
          if (typeof props.action.icon === 'function') {
            return props.action.icon(props.data)
          }
          return <MTableAction {...props} />
        },
        Container: (props) => (
          <Paper {...props} elevation={filter === 'archived' ? 0 : 1} />
        ),
      }}
      localization={{
        body: {
          emptyDataSourceMessage: 'No plans found',
          editRow: { deleteText: 'Delete plan? This action cannot be undone.' },
        },
      }}
      options={{
        actionsColumnIndex: -1,
        paging: false,
        selection: true,
        toolbar: true,
      }}
      actions={[
        {
          action: (rowData) => ({
            disabled: rowData.archived,
            icon: rowData._id === starredPlan ? 'star' : () => <StarBorder />,
            onClick: (e, data) => handleSetStarredClick(data),
            tooltip: rowData._id === starredPlan ? 'Unstar plan' : 'Star plan',
          }),
          position: 'row',
        },
        {
          icon: 'list',
          onClick: (e, data) => handleViewShoppingListClick(data),
          tooltip: 'View shopping list',
          position: 'row',
        },
        {
          icon: 'visibility',
          onClick: (e, data) => handleLoadViewClick(data),
          position: 'row',
          tooltip: 'View plan',
        },
        {
          icon: 'edit',
          onClick: (e, data) => handleLoadUpdateClick(data),
          tooltip: 'Update plan',
          position: 'row',
        },
        {
          icon: 'library_add',
          onClick: (e, data) => handleDuplicatePlanClick(data),
          position: 'row',
          tooltip: 'Copy plan',
        },
        {
          action: (rowData) => ({
            icon: rowData.archived ? 'unarchive' : 'archive',
            onClick: (e, data) => handleArchiveClick(data),
            tooltip: rowData.archived ? 'Unarchive plan' : 'Archive plan',
          }),
          position: 'row',
        },
        {
          icon: (data) => (
            <BulkDeleteConfirmationAlert
              data={data}
              name='plan'
              onDelete={handleDeleteManyClick}
            />
          ),
          position: 'toolbarOnSelect',
          tooltip: 'Delete selected plans',
        },
      ]}
      editable={{
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            const newData = [...data]
            const index = oldData.tableData.id
            newData.splice(index, 1)
            setData([...newData])
            onDelete(oldData)
            resolve()
          }),
      }}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PlansTable)
