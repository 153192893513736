import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import MealsDialog from './MealsDialog'
import MealsTable from './MealsTable'
import PageHeader from '../PageHeader'
import Spinner from '../Spinner'

import {
  MEALS_PAGE_LOADED,
  MEALS_PAGE_UNLOADED,
} from '../../constants/actionTypes'
import agent from '../../agent'

const mapStateToProps = (state) => ({
  pageLoaded: state.meals.pageLoaded,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({
      type: MEALS_PAGE_LOADED,
      payload: Promise.all([agent.Meals.getAll(), agent.Recipes.getAll()]),
    }),
  onUnload: () => dispatch({ type: MEALS_PAGE_UNLOADED }),
})

const Meals = ({ onLoad, onUnload, pageLoaded }) => {
  const [accordionOpen, setAccordionOpen] = useState(false)

  useEffect(() => {
    onLoad()

    return () => {
      onUnload()
    }
  }, [])

  return (
    <>
      <PageHeader title='Manage Meals'>
        <MealsDialog />
      </PageHeader>
      {pageLoaded ? (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MealsTable filter='unarchived' />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5' gutterBottom>
              Archived Meals
            </Typography>
            <Accordion onChange={(ev, expanded) => setAccordionOpen(expanded)}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                Click to {accordionOpen ? 'hide' : 'show'} archived meals
              </AccordionSummary>
              <AccordionDetails style={{ display: 'block' }}>
                <MealsTable filter='archived' />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      ) : (
        <Spinner />
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Meals)
