import superagentPromise from 'superagent-promise'
import _superagent from 'superagent'

const superagent = superagentPromise(_superagent, global.Promise)

const API_ROOT =
  process.env.NODE_ENV === 'production'
    ? 'https://my-plate-app.herokuapp.com/api'
    : 'http://localhost:4000/api'

const responseBody = (res) => res.body

let token = null
let tokenPlugin = (req) => {
  if (token) {
    req.set('authorization', `Token ${token}`)
  }
}

const requests = {
  // @todo update superagent-promise from v1.1.0 when available
  // superagent-promise does not allow for body to pass, so it has been manually added to the package file
  // This is a known issue, see: https://github.com/lightsofapollo/superagent-promise/issues/28
  del: (url, body) =>
    superagent
      .del(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  get: (url) =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
}

const Auth = {
  current: () => requests.get('/user'),
  login: (username, password) =>
    requests.post('/users/login', { user: { username, password } }),
  register: (user) => requests.post('/users', { user }),
  save: (user) => requests.put('/user', { user }),
}

const Categories = {
  add: (category) => requests.post('/categories', { category }),
  delete: (category) => requests.del(`/categories/${category.slug}`),
  deleteMany: (slugs) =>
    requests.del('/categories/bulk', { categories: slugs }),
  getAll: () => requests.get('/categories'),
  update: (category) =>
    requests.put(`/categories/${category.slug}`, { category }),
}

const Ingredients = {
  add: (ingredient) => requests.post('/ingredients', { ingredient }),
  delete: (ingredient) => requests.del(`/ingredients/${ingredient.slug}`),
  deleteMany: (slugs) =>
    requests.del('/ingredients/bulk', { ingredients: slugs }),
  getAll: () => requests.get('/ingredients'),
  update: (ingredient) =>
    requests.put(`/ingredients/${ingredient.slug}`, { ingredient }),
}

const Meals = {
  add: (meal) => requests.post('/meals', { meal }),
  delete: (meal) => requests.del(`/meals/${meal.slug}`),
  deleteMany: (slugs) => requests.del('/meals/bulk', { meals: slugs }),
  getAll: () => requests.get('/meals'),
  update: (meal) => requests.put(`/meals/${meal.slug}`, { meal }),
}

const Plans = {
  add: (plan) => requests.post('/plans', { plan }),
  delete: (plan) => requests.del(`/plans/${plan.slug}`),
  deleteMany: (plans) => requests.del('/plans/bulk', { plans }),
  getAll: () => requests.get('/plans'),
  update: (plan) => requests.put(`/plans/${plan.slug}`, { plan }),
}

const Recipes = {
  add: (recipe) => requests.post('/recipes', { recipe }),
  delete: (recipe) => requests.del(`/recipes/${recipe.slug}`),
  deleteMany: (slugs) => requests.del('/recipes/bulk', { recipes: slugs }),
  getAll: () => requests.get('/recipes'),
  update: (recipe) => requests.put(`/recipes/${recipe.slug}`, { recipe }),
}

const Settings = {
  update: (settings) => requests.put('/user/settings', { settings }),
}

const Stores = {
  add: (store) => requests.post('/stores', { store }),
  delete: (store) => requests.del(`/stores/${store.slug}`),
  getAll: () => requests.get('/stores'),
  update: (store) => requests.put(`/stores/${store.slug}`, { store }),
}

const Users = {
  getAll: () => requests.get('/users?role=subscriber'),
  delete: (user) => requests.del(`/users/subscriber/${user.username}`),
  update: (user) => requests.put(`/users/${user.username}`, { user }),
}

export default {
  Auth,
  Categories,
  Ingredients,
  Meals,
  Plans,
  Recipes,
  Settings,
  Stores,
  Users,
  setToken: (_token) => {
    token = _token
  },
}
