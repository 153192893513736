import {
  ADD_STORE,
  ASYNC_START,
  DELETE_STORE,
  PLANS_PAGE_LOADED,
  PLANS_PAGE_UNLOADED,
  STORE_DIALOG_LOADED,
  STORE_DIALOG_UNLOADED,
  STORES_PAGE_LOADED,
  STORES_PAGE_UNLOADED,
  UPDATE_STORE,
} from '../constants/actionTypes'

const defaultState = {
  editor: { store: null },
  errors: null,
  inProgress: false,
  pageLoaded: false,
  stores: [],
}

const sortByName = (a, b) => a.name.localeCompare(b.name)

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_STORE:
      return {
        ...state,
        stores: action.error
          ? state.stores
          : [...state.stores, action.payload.store].sort((a, b) =>
              sortByName(a, b)
            ),
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    case ASYNC_START:
      if (action.subtype === ADD_STORE || action.subtype === UPDATE_STORE) {
        return { ...state, errors: null, inProgress: true }
      } else if (action.subtype === STORES_PAGE_LOADED) {
        return { ...state, errors: null, pageLoaded: false }
      }
      break
    case DELETE_STORE:
      return {
        ...state,
        stores: action.error
          ? state.stores
          : state.stores.filter(
              (store) => store.slug !== action.payload.store.slug
            ),
      }
    case PLANS_PAGE_LOADED:
      return {
        ...state,
        stores: action.error ? [] : action.payload[5].stores,
      }
    case STORE_DIALOG_LOADED:
      return { ...state, editor: { ...state.editor, store: action.store } }
    case STORE_DIALOG_UNLOADED:
      return {
        ...state,
        editor: { ...state.editor, store: null },
        errors: null,
      }
    case STORES_PAGE_LOADED:
      return {
        ...state,
        pageLoaded: true,
        stores: action.payload[0].stores,
      }
    case STORES_PAGE_UNLOADED:
    case PLANS_PAGE_UNLOADED:
      return defaultState
    case UPDATE_STORE:
      return {
        ...state,
        stores: action.error
          ? state.stores
          : state.stores
              .map((store) =>
                store._id === action.payload.store._id
                  ? action.payload.store
                  : store
              )
              .sort((a, b) => sortByName(a, b)),
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    default:
      return state
  }

  return state
}
