import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  BottomNavigation,
  BottomNavigationAction,
  Icon,
  makeStyles,
} from '@material-ui/core'
import { Settings, SupervisorAccount } from '@material-ui/icons'
import { loadCSS } from 'fg-loadcss'

import {
  NAV_TAB_CHANGE,
  NAV_LOADED,
  NAV_UNLOADED,
} from '../constants/actionTypes'

const useStyles = makeStyles({
  nav: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    zIndex: '1000',
  },
})

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
  navTabValue: state.nav.navTabValue,
  path: state.router.location.pathname,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: (tabValue, redirectTo) =>
    dispatch({ type: NAV_LOADED, value: tabValue, redirectTo }),
  onTabChange: (tabValue, redirectTo) =>
    dispatch({ type: NAV_TAB_CHANGE, value: tabValue, redirectTo }),
  onUnload: () => dispatch({ type: NAV_UNLOADED }),
})

const Navigation = ({
  currentUser,
  navTabValue,
  onLoad,
  onTabChange,
  onUnload,
  path,
}) => {
  const classes = useStyles()
  const userRole = currentUser.role

  const handleTabChange = (tabValue) => {
    onTabChange(tabValue, `/${tabValue}`)
  }

  useEffect(() => {
    const tabFromPath = path.split('/')[1]
    if (tabFromPath && tabFromPath !== '') {
      onLoad(tabFromPath, `/${tabFromPath}`)
    } else {
      const tabValue = userRole === 'admin' ? 'users' : 'plans'
      onLoad(tabValue, `/${tabValue}`)
    }

    return () => {
      onUnload()
    }
  }, [])

  useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.15.3/css/all.css',
      document.querySelector('#font-awesome-css')
    )

    return () => {
      node.parentNode.removeChild(node)
    }
  })

  if (userRole === 'admin') {
    return (
      <BottomNavigation
        className={classes.nav}
        onChange={(event, newValue) => {
          handleTabChange(newValue)
        }}
        showLabels
        value={navTabValue}
      >
        <BottomNavigationAction
          label='Users'
          icon={<SupervisorAccount />}
          value='users'
        />
        <BottomNavigationAction
          label='Settings'
          icon={<Settings />}
          value='settings'
        />
      </BottomNavigation>
    )
  }

  return (
    <BottomNavigation
      className={classes.nav}
      onChange={(event, newValue) => {
        handleTabChange(newValue)
      }}
      showLabels
      value={navTabValue}
    >
      <BottomNavigationAction
        label='Plans'
        icon={<Icon className='fa fa-calendar-week' />}
        value='plans'
      />
      <BottomNavigationAction
        label='Meals'
        icon={<Icon className='fa fa-utensils' />}
        value='meals'
      />
      <BottomNavigationAction
        label='Recipes'
        icon={<Icon className='fa fa-hamburger' />}
        value='recipes'
      />
      <BottomNavigationAction
        label='Ingredients'
        icon={<Icon className='fa fa-carrot' />}
        value='ingredients'
      />
      <BottomNavigationAction
        label='Settings'
        icon={<Settings />}
        value='settings'
      />
    </BottomNavigation>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
