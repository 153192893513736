import React from 'react'
import { Tooltip } from '@material-ui/core'
import { AcUnit } from '@material-ui/icons'

const DefrostIcon = (props) => {
  return (
    <Tooltip title='requires defrost' arrow>
      <AcUnit
        color='primary'
        size='small'
        style={{ marginLeft: '8px' }}
        {...props}
      />
    </Tooltip>
  )
}

export default DefrostIcon
