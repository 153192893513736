import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

import IngredientDialog from '../Ingredients/IngredientDialog'

import { RECIPE_INGREDIENT_ONBLUR } from '../../constants/actionTypes'

const filter = createFilterOptions()

const mapStateToProps = (state) => ({
  editor: state.recipes.editor,
  ingredients: state.ingredients.ingredients,
})

const mapDispatchToProps = (dispatch) => ({
  // onBlur clears the newly-added ingredient from editor state so it is not used again when adding a new ingredient row
  onBlur: () => dispatch({ type: RECIPE_INGREDIENT_ONBLUR }),
})

const IngredientSelector = ({
  editor,
  ingredients,
  onBlur,
  onChange,
  value,
}) => {
  const [newIngredient, setNewIngredient] = useState(null)
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState(value ?? null)

  // Used for grouping ingredients in the selector dropdown
  const mappedOptions = ingredients.map((ingredient) => {
    const firstLetter = ingredient.name[0].toUpperCase()
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...ingredient,
    }
  })

  const handleChange = (e, value) => {
    // This conditional prevents an issue with Autocomplete changing the value prop from an object-type to a string-type when autoSelect is true
    if (typeof value !== 'string') {
      setSelected(value)
      onChange(e, value)
    }
    onBlur()
  }

  const handleDialogClose = () => {
    // Clear the new ingredient if ingredient dialog closed to allow the dialog to be opened again
    setNewIngredient(null)
  }

  useEffect(() => {
    // Update the options in the Autocomplete when a new ingredient is created
    setOptions(mappedOptions)
  }, [ingredients])

  useEffect(() => {
    // Update input selection with ingredient object when a new ingredient is created
    if (editor.ingredient) {
      setSelected(editor.ingredient)
      onChange({}, editor.ingredient)
      onBlur()
    }
  }, [editor.ingredient])

  // @todo material-table is not stopping enter key propogation causing row action to occur unexpectedly
  //         and MaterialTableFix prevents the whole row from being editable
  const handleKey = (e) => {
    if (e.key === 'Enter') e.stopPropagation()
  }

  return (
    <>
      <Autocomplete
        autoHighlight
        autoSelect
        freeSolo
        options={options}
        value={selected}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          if (params.inputValue !== '')
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            })
          return filtered
        }}
        getOptionLabel={(option) => {
          if (typeof option === 'string') return option
          if (option.inputValue) return option.inputValue
          return option.name
        }}
        getOptionSelected={(option, value) => option._id === value?._id}
        groupBy={(option) => option.firstLetter}
        onKeyDown={(e) => handleKey(e)}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            label='Ingredient name'
            variant='outlined'
          />
        )}
        onBlur={onBlur}
        onChange={(e, value) => {
          if (value && value.inputValue)
            setNewIngredient({ name: value.inputValue })
          handleChange(e, value)
        }}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.name, inputValue)
          const parts = parse(option.name, matches)
          return (
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          )
        }}
      />
      <IngredientDialog
        hideButton
        ingredient={newIngredient}
        isOpen={!!newIngredient}
        onClose={handleDialogClose}
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(IngredientSelector)
