import {
  ADD_CATEGORY,
  ASYNC_START,
  CATEGORY_DIALOG_UNLOADED,
  CATEGORIES_PAGE_LOADED,
  CATEGORIES_PAGE_UNLOADED,
  DELETE_CATEGORY,
  DELETE_CATEGORIES,
  INGREDIENTS_PAGE_LOADED,
  INGREDIENTS_PAGE_UNLOADED,
  PLANS_PAGE_LOADED,
  PLANS_PAGE_UNLOADED,
  RECIPES_PAGE_LOADED,
  RECIPES_PAGE_UNLOADED,
  STORES_PAGE_LOADED,
  STORES_PAGE_UNLOADED,
  UPDATE_CATEGORY,
} from '../constants/actionTypes'

const defaultState = {
  errors: null,
  categories: [],
  inProgress: false,
  pageLoaded: false,
}

const sortByName = (a, b) => a.name.localeCompare(b.name)

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_CATEGORY:
      return {
        ...state,
        categories: action.error
          ? state.categories
          : [...state.categories, action.payload.category].sort((a, b) =>
              sortByName(a, b)
            ),
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    case ASYNC_START:
      if (
        action.subtype === ADD_CATEGORY ||
        action.subtype === UPDATE_CATEGORY
      ) {
        return { ...state, errors: null, inProgress: true }
      } else if (action.subtype === CATEGORIES_PAGE_LOADED) {
        return { ...state, errors: null, pageLoaded: false }
      }
      break
    case DELETE_CATEGORY:
      return {
        ...state,
        categories: action.error
          ? state.categories
          : state.categories.filter(
              (category) => category.slug !== action.payload.category.slug
            ),
      }
    case DELETE_CATEGORIES:
      return {
        ...state,
        categories: action.error
          ? state.categories
          : state.categories.filter(
              (category) => !action.payload.categories.includes(category.slug)
            ),
      }
    case CATEGORY_DIALOG_UNLOADED:
      return { ...state, errors: null }
    case CATEGORIES_PAGE_LOADED:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        categories: action.error ? [] : action.payload.categories,
        inProgress: false,
        pageLoaded: true,
      }
    case CATEGORIES_PAGE_UNLOADED:
    case INGREDIENTS_PAGE_UNLOADED:
    case PLANS_PAGE_UNLOADED:
    case RECIPES_PAGE_UNLOADED:
    case STORES_PAGE_UNLOADED:
      return defaultState
    case INGREDIENTS_PAGE_LOADED:
    case STORES_PAGE_LOADED:
      return {
        ...state,
        categories: action.error ? [] : action.payload[1].categories,
      }
    case PLANS_PAGE_LOADED:
      return {
        ...state,
        categories: action.error ? [] : action.payload[4].categories,
      }
    case RECIPES_PAGE_LOADED:
      return {
        ...state,
        categories: action.error ? [] : action.payload[2].categories,
      }
    case UPDATE_CATEGORY:
      return {
        ...state,
        categories: action.error
          ? state.categories
          : state.categories
              .map((category) =>
                category._id === action.payload.category._id
                  ? action.payload.category
                  : category
              )
              .sort((a, b) => sortByName(a, b)),
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    default:
      return state
  }

  return state
}
