import {
  ADD_INGREDIENT,
  ASYNC_START,
  INGREDIENT_DIALOG_UNLOADED,
  INGREDIENTS_PAGE_LOADED,
  INGREDIENTS_PAGE_UNLOADED,
  DELETE_INGREDIENT,
  DELETE_INGREDIENTS,
  PLANS_PAGE_LOADED,
  PLANS_PAGE_UNLOADED,
  RECIPES_PAGE_LOADED,
  RECIPES_PAGE_UNLOADED,
  UPDATE_INGREDIENT,
} from '../constants/actionTypes'

const defaultState = {
  errors: null,
  ingredients: [],
  inProgress: false,
  pageLoaded: false,
}

const sortByName = (a, b) => a.name.localeCompare(b.name)

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_INGREDIENT:
      return {
        ...state,
        ingredients: action.error
          ? state.ingredients
          : [...state.ingredients, action.payload.ingredient].sort((a, b) =>
              sortByName(a, b)
            ),
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    case ASYNC_START:
      if (
        action.subtype === ADD_INGREDIENT ||
        action.subtype === UPDATE_INGREDIENT
      ) {
        return { ...state, errors: null, inProgress: true }
      } else if (action.subtype === INGREDIENTS_PAGE_LOADED) {
        return { ...state, errors: null, pageLoaded: false }
      }
      break
    case DELETE_INGREDIENT:
      return {
        ...state,
        ingredients: action.error
          ? state.ingredients
          : state.ingredients.filter(
              (ingredient) => ingredient.slug !== action.payload.ingredient.slug
            ),
      }
    case DELETE_INGREDIENTS:
      return {
        ...state,
        ingredients: action.error
          ? state.ingredients
          : state.ingredients.filter(
              (ingredient) =>
                !action.payload.ingredients.includes(ingredient.slug)
            ),
      }
    case INGREDIENT_DIALOG_UNLOADED:
      return { ...state, errors: null }
    case INGREDIENTS_PAGE_LOADED:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        ingredients: action.error ? [] : action.payload[0].ingredients,
        inProgress: false,
        pageLoaded: true,
      }
    case INGREDIENTS_PAGE_UNLOADED:
    case RECIPES_PAGE_UNLOADED:
    case PLANS_PAGE_UNLOADED:
      return defaultState
    case PLANS_PAGE_LOADED:
      return {
        ...state,
        ingredients: action.error ? [] : action.payload[3].ingredients,
      }
    case RECIPES_PAGE_LOADED:
      return {
        ...state,
        ingredients: action.error ? [] : action.payload[1].ingredients,
      }
    case UPDATE_INGREDIENT:
      return {
        ...state,
        ingredients: action.error
          ? state.ingredients
          : state.ingredients
              .map((ingredient) =>
                ingredient._id === action.payload.ingredient._id
                  ? action.payload.ingredient
                  : ingredient
              )
              .sort((a, b) => sortByName(a, b)),
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    default:
      return state
  }

  return state
}
