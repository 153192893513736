import React from 'react'
import { Paper, Typography, makeStyles } from '@material-ui/core'
import Div100vh from 'react-div-100vh'

import Logo from './Logo'
import background from './../images/plate-background.png'

const useStyles = makeStyles((theme) => ({
  background: {
    alignItems: 'center',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
  logo: {
    marginBottom: theme.spacing(4),
    width: 160,
  },
  panel: {
    alignItems: 'center',
    borderRadius: '1rem',
    display: 'flex',
    flexFlow: 'column nowrap',
    overflowY: 'scroll',
    padding: '2rem 4rem',
    position: 'relative',
  },
  text: {
    color: theme.palette.primary.main,
    fontStyle: 'italic',
  },
}))

const Loading = (props) => {
  const classes = useStyles()

  return (
    <Div100vh className={classes.background}>
      <Paper elevation={12} className={classes.panel}>
        <Logo className={classes.logo} />
        <Typography className={classes.text} variant='h4'>
          Loading...
        </Typography>
      </Paper>
    </Div100vh>
  )
}

export default Loading
