import React, { useState } from 'react'
import MUIRichTextEditor from 'mui-rte'
import { convertToRaw } from 'draft-js'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  error: {
    color: '#f44336',
    fontSize: '0.75rem',
    fontWeight: 400,
    letterSpacing: '0.03333em',
    lineHeight: 1.66,
    margin: 0,
    marginTop: '3px',
    textAlign: 'left',
  },
}))

const RichEditor = (props) => {
  const [errors] = useState(null)
  // const [errors, setErrors] = useState(null)

  const classes = useStyles()

  const handleBlur = () => {
    !props.readOnly && props.onEditorBlur(props.name, true, false)
  }

  const handleChange = (state) => {
    !props.readOnly &&
      props.onEditorChange(
        props.name,
        JSON.stringify(convertToRaw(state.getCurrentContent())),
        false
      )
    /**
     * The error state for this component is handled by local state rather than
     * passed in from formik with props. Formik is overridding the value of the
     * error when mounting the component and when submitting the form, if we
     * can stop that behavior, then we could allow formik to control the error
     * state.
     */
    // setErrors(!state.getCurrentContent().hasText() ? 'Required' : null) // disable error handling for this input (not a required input)
  }

  // Component css styles supplied as theme overrides in Theme.js
  return (
    <>
      <MUIRichTextEditor
        controls={['numberList', 'bold', 'italic', 'underline', 'undo', 'redo']}
        defaultValue={props.initialValue}
        error={errors && props.touched}
        label={props.placeholder}
        onBlur={handleBlur}
        onChange={handleChange}
        {...props}
      />
      {errors && props.touched && <div className={classes.error}>{errors}</div>}
    </>
  )
}

export default RichEditor
