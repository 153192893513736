import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, makeStyles } from '@material-ui/core'

import IngredientDialog from './IngredientDialog'
import IngredientsTable from './IngredientsTable'
import PageHeader from '../PageHeader'
import Spinner from '../Spinner'
import agent from '../../agent'

import {
  INGREDIENTS_PAGE_LOADED,
  INGREDIENTS_PAGE_UNLOADED,
} from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  button: {
    'marginRight': theme.spacing(2),
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
}))

const mapStateToProps = (state) => ({
  ...state.ingredients,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({
      type: INGREDIENTS_PAGE_LOADED,
      payload: Promise.all([
        agent.Ingredients.getAll(),
        agent.Categories.getAll(),
      ]),
    }),
  onUnload: () => dispatch({ type: INGREDIENTS_PAGE_UNLOADED }),
})

const Ingredients = ({ onLoad, onUnload, pageLoaded }) => {
  const classes = useStyles()

  useEffect(() => {
    onLoad()

    return () => {
      onUnload()
    }
  }, [])

  return (
    <>
      <PageHeader title='Manage Ingredients'>
        <Button className={classes.button} color='secondary'>
          <Link to='/ingredients/categories'>Manage categories</Link>
        </Button>
        <IngredientDialog />
      </PageHeader>
      {pageLoaded ? <IngredientsTable /> : <Spinner />}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Ingredients)
