import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Add, Edit } from '@material-ui/icons'

import ActionButton from '../ActionButton'
import ListErrors from '../ListErrors'
import agent from '../../agent'

import {
  ADD_USER,
  USER_DIALOG_UNLOADED,
  UPDATE_USER,
} from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const mapStateToProps = (state) => ({
  ...state.users,
})

const mapDispatchToProps = (dispatch) => ({
  onAdd: (user) =>
    dispatch({
      type: ADD_USER,
      payload: agent.Auth.register(user),
      snackbar: { message: 'User added', variant: 'success' },
    }),
  onExit: () => dispatch({ type: USER_DIALOG_UNLOADED }),
  onUpdate: (user) =>
    dispatch({
      type: UPDATE_USER,
      payload: agent.Users.update(user),
      snackbar: { message: 'User updated', variant: 'success' },
    }),
})

const UserDialog = ({ errors, inProgress, onAdd, onExit, onUpdate, user }) => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmitAdd = (values) => {
    onAdd({ ...values, role: 'subscriber' })
  }

  const handleSubmitUpdate = (values) => {
    onUpdate({ ...user, ...values })
  }

  useEffect(() => {
    // Close the dialog only if there are no errors
    if (!inProgress && !errors) setOpen(false)
  }, [inProgress])

  return (
    <>
      {user ? (
        <ActionButton action='Edit user' onClick={handleClickOpen}>
          <Edit />
        </ActionButton>
      ) : (
        <Fab color='primary' onClick={handleClickOpen} variant='extended'>
          <Add className={classes.icon} />
          Add user
        </Fab>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        onClick={(ev) => ev.stopPropagation()}
        onFocus={(ev) => ev.stopPropagation()}
        TransitionProps={{ onExit: onExit }}
      >
        <Formik
          initialValues={{
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            username: user?.username || '',
            password: '',
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            username: Yup.string().required('Required'),
            password: Yup.string().required(!user ? 'Required' : ''),
          })}
          onSubmit={async (values) => {
            user ? handleSubmitUpdate(values) : handleSubmitAdd(values)
          }}
        >
          <Form>
            <DialogTitle>{user ? 'Edit User' : 'Add User'}</DialogTitle>
            <DialogContent>
              <ListErrors errors={errors} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field
                    autoFocus={user ? false : true}
                    color='primary'
                    component={TextField}
                    disabled={inProgress}
                    label='First Name'
                    name='firstName'
                    type='text'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    color='primary'
                    component={TextField}
                    disabled={inProgress}
                    label='Last Name'
                    name='lastName'
                    type='text'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    color='primary'
                    component={TextField}
                    disabled={!!user || inProgress}
                    label='Username'
                    name='username'
                    type='text'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    color='primary'
                    component={TextField}
                    disabled={inProgress}
                    label='Password'
                    name='password'
                    type='text'
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                disabled={inProgress}
                type='submit'
                variant='contained'
              >
                {user
                  ? inProgress
                    ? 'Updating...'
                    : 'Update'
                  : inProgress
                  ? 'Adding...'
                  : 'Add'}
              </Button>
              <Button
                disabled={inProgress}
                onClick={handleClose}
                variant='contained'
              >
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDialog)
