import {
  ADD_MEAL,
  ASYNC_START,
  DELETE_MEAL,
  DELETE_MEALS,
  MEAL_DIALOG_LOADED,
  MEAL_DIALOG_UNLOADED,
  MEALS_PAGE_LOADED,
  MEALS_PAGE_UNLOADED,
  PLANS_PAGE_LOADED,
  PLANS_PAGE_UNLOADED,
  UPDATE_MEAL,
} from '../constants/actionTypes'

const defaultState = {
  editor: { meal: null },
  errors: null,
  inProgress: false,
  meals: [],
  pageLoaded: false,
}

const sortByTitle = (a, b) => a.title.localeCompare(b.title)

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_MEAL:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        meals: action.error
          ? state.meals
          : [...state.meals, action.payload.meal].sort((a, b) =>
              sortByTitle(a, b)
            ),
      }
    case ASYNC_START:
      if (action.subtype === ADD_MEAL || action.subtype === UPDATE_MEAL) {
        return { ...state, errors: null, inProgress: true }
      } else if (action.subtype === MEALS_PAGE_LOADED) {
        return { ...state, errors: null, pageLoaded: false }
      }
      break
    case DELETE_MEAL:
      return {
        ...state,
        meals: action.error
          ? state.meals
          : state.meals.filter(
              (meal) => meal.slug !== action.payload.meal.slug
            ),
      }
    case DELETE_MEALS:
      return {
        ...state,
        meals: action.error
          ? state.meals
          : state.meals.filter(
              (meal) => !action.payload.meals.includes(meal.slug)
            ),
      }
    case MEAL_DIALOG_LOADED:
      return { ...state, editor: { ...state.editor, meal: action.meal } }
    case MEAL_DIALOG_UNLOADED:
      return { ...state, editor: { ...state.editor, meal: null }, errors: null }
    case MEALS_PAGE_LOADED:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        meals: action.error ? [] : action.payload[0].meals,
        pageLoaded: true,
      }
    case MEALS_PAGE_UNLOADED:
    case PLANS_PAGE_UNLOADED:
      return defaultState
    case PLANS_PAGE_LOADED:
      return {
        ...state,
        meals: action.error ? [] : action.payload[1].meals,
      }
    case UPDATE_MEAL:
      return {
        ...state,
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
        meals: action.error
          ? state.meals
          : state.meals
              .map((meal) =>
                meal._id === action.payload.meal._id
                  ? action.payload.meal
                  : meal
              )
              .sort((a, b) => sortByTitle(a, b)),
      }
    default:
      return state
  }

  return state
}
