import {
  ADD_PLAN,
  ASYNC_START,
  DELETE_PLAN,
  DELETE_PLANS,
  PLAN_DIALOG_LOADED,
  PLAN_DIALOG_UNLOADED,
  PLAN_LIST_DIALOG_LOADED,
  PLAN_LIST_DIALOG_UNLOADED,
  PLAN_VIEW_LOADED,
  PLAN_VIEW_UNLOADED,
  PLANS_PAGE_LOADED,
  PLANS_PAGE_UNLOADED,
  UPDATE_PLAN,
} from '../constants/actionTypes'

const defaultState = {
  editor: { plan: null },
  errors: null,
  inProgress: false,
  pageLoaded: false,
  plans: [],
  viewer: { plan: null, type: null },
}

const sortByTitle = (a, b) => a.title.localeCompare(b.title)

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_PLAN:
      return {
        ...state,
        plans: action.error
          ? state.plans
          : [...state.plans, action.payload.plan].sort((a, b) =>
              sortByTitle(a, b)
            ),
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    case ASYNC_START:
      if (action.subtype === ADD_PLAN || action.subtype === UPDATE_PLAN) {
        return { ...state, errors: null, inProgress: true }
      } else if (action.subtype === PLANS_PAGE_LOADED) {
        return { ...state, errors: null, pageLoaded: false }
      }
      break
    case DELETE_PLAN:
      return {
        ...state,
        plans: action.error
          ? state.plans
          : state.plans.filter(
              (plan) => plan.slug !== action.payload.plan.slug
            ),
      }
    case DELETE_PLANS:
      return {
        ...state,
        plans: action.error
          ? state.plans
          : state.plans.filter(
              (plan) => !action.payload.plans.includes(plan.slug)
            ),
      }
    case PLAN_DIALOG_LOADED:
      return { ...state, editor: { ...state.editor, plan: action.plan } }
    case PLAN_DIALOG_UNLOADED:
      return { ...state, editor: { ...state.editor, plan: null }, errors: null }
    case PLAN_LIST_DIALOG_LOADED:
      return {
        ...state,
        viewer: { ...state.viewer, plan: action.plan, type: 'list' },
      }
    case PLAN_LIST_DIALOG_UNLOADED:
    case PLAN_VIEW_UNLOADED:
      return { ...state, viewer: { ...state.viewer, plan: null, type: null } }
    case PLAN_VIEW_LOADED:
      return {
        ...state,
        viewer: { ...state.viewer, plan: action.plan, type: 'plan' },
      }
    case PLANS_PAGE_LOADED:
      return {
        ...state,
        pageLoaded: true,
        plans: action.payload[0].plans,
      }
    case PLANS_PAGE_UNLOADED:
      return defaultState
    case UPDATE_PLAN:
      return {
        ...state,
        plans: action.error
          ? state.plans
          : state.plans
              .map((plan) =>
                plan._id === action.payload.plan._id
                  ? action.payload.plan
                  : plan
              )
              .sort((a, b) => sortByTitle(a, b)),
        errors: action.error ? action.payload.errors : null,
        inProgress: false,
      }
    default:
      return state
  }

  return state
}
