import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

import FabAdd from '../FabAdd'
import ListErrors from '../ListErrors'
import RecipeSelector from './RecipeSelector'

import {
  ADD_MEAL,
  MEAL_DIALOG_UNLOADED,
  UPDATE_MEAL,
} from '../../constants/actionTypes'
import { MEAL_TYPES } from '../../constants/mealTypes'
import agent from '../../agent'

const mapStateToProps = (state) => ({
  ...state.meals,
  recipes: state.recipes.recipes,
})

const mapDispatchToProps = (dispatch) => ({
  onAdd: (meal) =>
    dispatch({
      type: ADD_MEAL,
      payload: agent.Meals.add(meal),
      snackbar: { message: 'Meal added', variant: 'success' },
    }),
  onExit: () => dispatch({ type: MEAL_DIALOG_UNLOADED }),
  onUpdate: (meal) =>
    dispatch({
      type: UPDATE_MEAL,
      payload: agent.Meals.update(meal),
      snackbar: { message: 'Meal updated', variant: 'success' },
    }),
})

const MealsDialog = ({
  editor,
  errors,
  inProgress,
  onAdd,
  onExit,
  onUpdate,
  recipes,
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmitAdd = (values) => {
    onAdd({ ...values })
  }

  const handleSubmitUpdate = (values) => {
    onUpdate({ ...editor.meal, ...values })
  }

  useEffect(() => {
    // Close the dialog only if there are no errors
    if (!inProgress && !errors) setOpen(false)
  }, [inProgress])

  useEffect(() => {
    // Open the dialog if an editor.meal object is defined
    setOpen(!!editor.meal)
  }, [editor.meal])

  return (
    <>
      <FabAdd item='meal' onClick={handleClickOpen} />

      <Dialog
        fullWidth
        fullScreen={fullScreen}
        maxWidth='md'
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
        TransitionProps={{
          onExit: onExit,
        }}
      >
        <Formik
          initialValues={{
            title:
              !editor.meal?._id && editor.meal?.title
                ? `Copy of ${editor.meal?.title}`
                : editor.meal?.title || '',
            recipes: editor.meal?.recipes || [],
            mealType: editor.meal?.mealType || '',
            notes: editor.meal?.notes || '',
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('Required'),
            recipes: Yup.array(),
            mealType: Yup.string().required('Required'),
            notes: Yup.string(),
          })}
          onSubmit={async (values) => {
            editor.meal?._id
              ? handleSubmitUpdate(values)
              : handleSubmitAdd(values)
          }}
        >
          {(formik) => (
            <Form>
              <DialogTitle>
                {editor.meal?._id ? 'Update meal' : 'Add meal'}
              </DialogTitle>
              <DialogContent>
                <ListErrors errors={errors} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      autoFocus={!editor.meal}
                      component={TextField}
                      disabled={inProgress}
                      id='title'
                      label='Meal Title'
                      name='title'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      disabled={inProgress}
                      id='mealType'
                      label='Meal Type'
                      name='mealType'
                      type='select'
                      select
                      fullWidth
                    >
                      {MEAL_TYPES.map((name) => {
                        const value = name.toLowerCase()
                        return (
                          <MenuItem key={value} value={value}>
                            {name}
                          </MenuItem>
                        )
                      })}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      disabled={inProgress}
                      id='notes'
                      label='Notes'
                      name='notes'
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RecipeSelector
                      onChange={formik.setFieldValue}
                      name='recipes'
                      recipes={recipes.filter((recipe) => !recipe.archived)}
                      values={formik.values.recipes}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  disabled={inProgress}
                  type='submit'
                  variant='contained'
                >
                  {editor.meal?._id
                    ? inProgress
                      ? 'Updating...'
                      : 'Update'
                    : inProgress
                    ? 'Adding...'
                    : 'Add'}
                </Button>
                <Button
                  disabled={inProgress}
                  onClick={handleClose}
                  variant='contained'
                >
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MealsDialog)
