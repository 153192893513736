import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import { Fab, Grid, Paper, Typography, makeStyles } from '@material-ui/core'
import { SaveAlt } from '@material-ui/icons'

import ListErrors from './ListErrors'
import PageHeader from './PageHeader'
import Stores from './Stores/Stores'
import agent from '../agent'

import {
  SETTINGS_PAGE_UNLOADED,
  SETTINGS_SAVED,
} from '../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}))

const mapStateToProps = (state) => ({
  ...state.settings,
  currentUser: state.common.currentUser,
  settings: state.common.settings,
})

const mapDispatchToProps = (dispatch) => ({
  onSubmitForm: (settings) =>
    dispatch({
      type: SETTINGS_SAVED,
      payload: Promise.all([
        agent.Auth.save(settings),
        agent.Settings.update(settings),
      ]),
      snackbar: { message: 'Settings saved', variant: 'success' },
    }),
  onUnload: () => dispatch({ type: SETTINGS_PAGE_UNLOADED }),
})

const Settings = ({
  currentUser,
  errors,
  inProgress,
  onSubmitForm,
  onUnload,
  settings,
}) => {
  const classes = useStyles()

  useEffect(() => {
    return () => {
      onUnload()
    }
  }, [])

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        longDuration: settings.longDuration,
        password: '',
      }}
      validationSchema={Yup.object({
        firstName: Yup.string(),
        lastName: Yup.string(),
        longDuration: Yup.number(),
        password: Yup.string(),
      })}
      onSubmit={async (values, form) => {
        const userSettings = { ...values }

        if (!userSettings.password) delete userSettings.password

        onSubmitForm(userSettings)

        form.resetForm({
          values: { ...userSettings, password: '' },
        })
      }}
    >
      <Form>
        <PageHeader title='Manage Account'>
          <Fab
            color='primary'
            disabled={inProgress}
            type='sumbit'
            variant='extended'
          >
            <SaveAlt className={classes.icon} />
            {inProgress ? 'Saving...' : 'Save settings'}
          </Fab>
        </PageHeader>
        <ListErrors errors={errors} />

        {currentUser.role !== 'admin' && (
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stores />
              </Grid>
            </Grid>
          </Paper>
        )}

        {/* <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h5' gutterBottom>
                Linked Accounts
              </Typography>
            </Grid>
          </Grid>
        </Paper> */}

        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h5' gutterBottom>
                Account Details
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                component={TextField}
                label='First name'
                name='firstName'
                type='text'
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                component={TextField}
                label='Last name'
                name='lastName'
                type='text'
                variant='outlined'
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>

        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h5' gutterBottom>
                Account Security
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                component={TextField}
                label='New password'
                name='password'
                type='password'
                variant='outlined'
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>

        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h5' gutterBottom>
                Recipes
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                component={TextField}
                label='Long prep time duration (min)'
                name='longDuration'
                type='number'
                variant='outlined'
                fullWidth
              />
              <Typography variant='caption'>
                Flag any recipe with a prep time longer than this
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Form>
    </Formik>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
