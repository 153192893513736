import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'

import MealSelector from './MealSelector'

const MealSelectorDialog = ({ day, meals, onAdd }) => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleExit = () => {}

  const handleSubmitAdd = (values) => {
    const result = { day, mealId: values.meal }
    onAdd(result)
    handleClose()
  }

  return (
    <>
      <Tooltip arrow title={`Add meal to ${day}`}>
        <Fab color='secondary' onClick={handleClickOpen} size='small'>
          <Add />
        </Fab>
      </Tooltip>

      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth='md'
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
        TransitionProps={{ onExit: handleExit }}
      >
        <Formik
          initialValues={{
            meal: '',
          }}
          onSubmit={(values) => {
            handleSubmitAdd(values)
          }}
        >
          {(formik) => (
            <Form>
              <DialogTitle>Add meal to {day}</DialogTitle>
              <DialogContent>
                <MealSelector
                  meals={meals}
                  name='meal'
                  onChange={formik.setFieldValue}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  disabled={!formik.values.meal}
                  type='submit'
                  variant='contained'
                >
                  Add
                </Button>
                <Button onClick={handleClose} variant='contained'>
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

export default MealSelectorDialog
