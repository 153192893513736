import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { IconButton, Paper, useTheme } from '@material-ui/core'
import { Close } from '@material-ui/icons'

const MealDraggable = ({ index, meal, onRemove, uniqueId }) => {
  const theme = useTheme()

  const getStyle = (isDragging, draggableStyle) => ({
    alignItems: 'center',
    background: isDragging ? theme.palette.secondary.light : 'white',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    userSelect: 'none',
    ...draggableStyle,
  })

  return (
    <Draggable key={uniqueId} draggableId={uniqueId} index={index}>
      {(provided, snapshot) => (
        <Paper
          elevation={3}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          {meal?.title || 'Deleted item'}
          <IconButton id={uniqueId} onClick={onRemove} size='small'>
            <Close />
          </IconButton>
        </Paper>
      )}
    </Draggable>
  )
}

export default MealDraggable
