import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

import FabAdd from '../FabAdd'
import ListErrors from '../ListErrors'
import agent from '../../agent'

import {
  ADD_CATEGORY,
  CATEGORY_DIALOG_UNLOADED,
  UPDATE_CATEGORY,
} from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  addIcon: {
    marginRight: theme.spacing(1),
  },
}))

const mapStateToProps = (state) => ({
  ...state.categories,
})

const mapDispatchToProps = (dispatch) => ({
  onAdd: (category) =>
    dispatch({
      type: ADD_CATEGORY,
      payload: agent.Categories.add(category),
      snackbar: { message: 'Category added', variant: 'success' },
    }),
  onExit: () => dispatch({ type: CATEGORY_DIALOG_UNLOADED }),
  onUpdate: (category) =>
    dispatch({
      type: UPDATE_CATEGORY,
      payload: agent.Categories.update(category),
      snackbar: { message: 'Category updated', variant: 'success' },
    }),
})

const CategoryDialog = ({
  errors,
  category,
  inProgress,
  onAdd,
  onExit,
  onUpdate,
}) => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmitAdd = (values) => {
    onAdd({ ...values })
  }

  const handleSubmitUpdate = (values) => {
    onUpdate({ ...category, ...values })
  }

  useEffect(() => {
    // Close the dialog only if there are no errors
    if (!inProgress && !errors) setOpen(false)
  }, [inProgress])

  return (
    <>
      <FabAdd item='category' onClick={handleClickOpen} />

      <Dialog
        fullScreen={fullScreen}
        maxWidth='lg'
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
        TransitionProps={{ onExit: onExit }}
      >
        <Formik
          initialValues={{
            name: category?.name || '',
            notes: category?.notes || '',
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
            notes: Yup.string(),
          })}
          onSubmit={async (values) => {
            category ? handleSubmitUpdate(values) : handleSubmitAdd(values)
          }}
        >
          <Form>
            <DialogTitle>
              {category ? 'Edit category' : 'Add category'}
            </DialogTitle>
            <DialogContent>
              <ListErrors errors={errors} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    autoFocus
                    color='primary'
                    component={TextField}
                    disabled={inProgress}
                    label='Category Name'
                    name='name'
                    type='text'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    color='primary'
                    component={TextField}
                    disabled={inProgress}
                    label='Category Notes'
                    name='notes'
                    type='text'
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={inProgress}
                color='primary'
                type='submit'
                variant='contained'
              >
                {category
                  ? inProgress
                    ? 'Updating...'
                    : 'Update'
                  : inProgress
                  ? 'Adding...'
                  : 'Add'}
              </Button>
              <Button
                disabled={inProgress}
                onClick={handleClose}
                variant='contained'
              >
                Cancel
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDialog)
