import React, { useState } from 'react'
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Info } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  checked: {
    '& + span': {
      'color': theme.palette.primary.light,
      'textDecoration': 'line-through',
      '& svg': {
        opacity: 0.5,
      },
    },
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  listItem: {
    alignItems: 'center',
    display: 'flex',
  },
}))

const ShoppingList = ({ list }) => {
  const classes = useStyles()

  const [checked, setChecked] = useState({})

  const handleChange = (e) => {
    setChecked({ ...checked, [e.target.name]: e.target.checked })
  }

  return Array.isArray(list) && list.length > 0 ? (
    <FormGroup>
      {list.map((item) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={checked[`${item.name}-${item.unit}`] ?? false}
              classes={{ checked: classes.checked }}
              onChange={handleChange}
              name={`${item.name}-${item.unit}`}
            />
          }
          key={`${item.id}-${item.unit}`}
          label={
            <span className={classes.listItem}>
              {item.quantity && item.unit
                ? `${item.name} (${item.quantity} ${item.unit})`
                : `${item.name}`}
              <Tooltip
                arrow
                enterTouchDelay={0}
                title={`Needed for: ${item.recipes.join(', ')}`}
              >
                <Info className={classes.icon} color='secondary' />
              </Tooltip>
            </span>
          }
        />
      ))}
    </FormGroup>
  ) : (
    <Typography>{list}</Typography>
  )
}

export default ShoppingList
