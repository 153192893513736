import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MaterialTable from '@material-table/core'
import { AddBox, StarBorder } from '@material-ui/icons'

import StoreDialog from './StoreDialog'

import {
  DELETE_STORE,
  SET_DEFAULT_STORE,
  STORE_DIALOG_LOADED,
} from '../../constants/actionTypes'
import agent from '../../agent'

const mapStateToProps = (state) => ({
  defaultStore: state.common.settings.defaultStore,
  stores: state.stores.stores,
})

const mapDispatchToProps = (dispatch) => ({
  onDelete: (store) =>
    dispatch({
      type: DELETE_STORE,
      payload: agent.Stores.delete(store),
      snackbar: { message: 'Store deleted', variant: 'success' },
    }),
  onLoadUpdate: (store) => dispatch({ type: STORE_DIALOG_LOADED, store }),
  onSetDefault: (settings) =>
    dispatch({
      type: SET_DEFAULT_STORE,
      payload: agent.Settings.update(settings),
      snackbar: { message: 'Default store saved', variant: 'success' },
    }),
})

const StoresTable = ({
  defaultStore,
  onDelete,
  onLoadUpdate,
  onSetDefault,
  stores,
}) => {
  const [data, setData] = useState([])

  const [columns] = useState([
    { title: 'Name', field: 'name' },
    { title: 'Notes', field: 'notes' },
  ])

  const handleLoadUpdateClick = (store) => {
    onLoadUpdate(store)
  }

  const handleSetDefaultClick = (store) => {
    const settings = { defaultStore: store }
    onSetDefault(settings)
  }

  const handleDuplicateStoreClick = (store) => {
    store._id = ''
    onLoadUpdate(store)
  }

  useEffect(() => {
    setData(stores)
  }, [stores])

  return (
    <>
      <StoreDialog />
      <MaterialTable
        title='Manage Stores'
        columns={columns}
        data={data}
        localization={{
          body: {
            emptyDataSourceMessage: 'No stores found',
            editRow: {
              deleteText: 'Delete store? This action cannot be undone.',
            },
          },
        }}
        actions={[
          {
            action: (rowData) => ({
              icon:
                rowData._id === defaultStore ? 'star' : () => <StarBorder />,
              onClick: (e, data) => handleSetDefaultClick(data),
              position: 'row',
              tooltip:
                rowData._id === defaultStore
                  ? 'Default store'
                  : 'Set as default store',
            }),
          },
          {
            action: (rowData) => ({
              icon: 'edit',
              onClick: (e, data) => handleLoadUpdateClick(data),
              position: 'row',
              tooltip: 'Update store',
            }),
          },
          {
            icon: 'library_add',
            onClick: (e, data) => handleDuplicateStoreClick(data),
            position: 'row',
            tooltip: 'Copy store',
          },
          {
            icon: () => <AddBox />,
            isFreeAction: true,
            onClick: () => handleLoadUpdateClick({}),
            position: 'toolbar',
            tooltip: 'Add store',
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          paging: false,
          toolbar: true,
        }}
        editable={{
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              const newData = [...data]
              const index = oldData.tableData.id
              newData.splice(index, 1)
              setData([...newData])
              onDelete(oldData)
              resolve()
            }),
        }}
        style={{ boxShadow: 'none' }}
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StoresTable)
