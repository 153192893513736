import React from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  headerLeft: {
    alignItems: 'center',
    display: 'flex',
  },
  headerRight: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageHeader: {
    marginBottom: theme.spacing(4),
  },
}))

const PageHeader = ({ children, title }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2} className={classes.pageHeader}>
      <Grid item xs={12} md={6} className={classes.headerLeft}>
        <Typography variant='h4'>{title}</Typography>
      </Grid>
      <Grid item xs={12} md={6} className={classes.headerRight}>
        {children}
      </Grid>
    </Grid>
  )
}

export default PageHeader
