import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Chip, Paper, useTheme } from '@material-ui/core'

const CategoryDraggable = ({ category, index }) => {
  const theme = useTheme()

  const getStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? theme.palette.secondary.light : 'white',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    userSelect: 'none',
    ...draggableStyle,
  })

  return (
    <Draggable draggableId={category.slug} index={index} key={category.slug}>
      {(provided, snapshot) => (
        <Paper
          elevation={3}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          {category.name}{' '}
          {category.isNew && (
            <Chip
              color='secondary'
              label='new'
              size='small'
              style={{ color: 'white', fontStyle: 'italic' }}
            />
          )}
        </Paper>
      )}
    </Draggable>
  )
}

export default CategoryDraggable
