import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { MTableAction } from '@material-table/core'
import MaterialTable from 'material-table'
import { Paper } from '@material-ui/core'

// import MaterialTable, { MTableAction } from '@material-table/core'
// @todo: Not sure why we were using this MaterialTable, but it does not support data exporting.
// Everything works with this new table, but there is a warning:
// Failed prop type: Invalid prop `actions[3]` supplied to `MaterialTable`.

import BulkDeleteConfirmationAlert from '../BulkDeleteConfirmationAlert'

import agent from '../../agent'

import {
  DELETE_RECIPE,
  DELETE_RECIPES,
  RECIPE_DIALOG_LOADED,
  RECIPE_VIEW_LOADED,
  UPDATE_RECIPE,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  recipes: state.recipes.recipes,
})

const mapDispatchToProps = (dispatch) => ({
  onDelete: (recipe) =>
    dispatch({
      type: DELETE_RECIPE,
      payload: agent.Recipes.delete(recipe),
      snackbar: { message: 'Recipe deleted', variant: 'success' },
    }),
  onDeleteMany: (slugs) =>
    dispatch({
      type: DELETE_RECIPES,
      payload: agent.Recipes.deleteMany(slugs),
      snackbar: {
        message: `${slugs.length} recipe${slugs.length > 1 ? 's' : ''} deleted`,
        variant: 'success',
      },
    }),
  onLoadUpdate: (recipe) => dispatch({ type: RECIPE_DIALOG_LOADED, recipe }),
  onLoadView: (recipe) => dispatch({ type: RECIPE_VIEW_LOADED, recipe }),
  onUpdateArchiveStatus: (recipe, status) =>
    dispatch({
      type: UPDATE_RECIPE,
      payload: agent.Recipes.update(recipe),
      snackbar: { message: `Recipe ${status}`, variant: 'success' },
    }),
})

const RecipesTable = ({
  filter,
  onDelete,
  onDeleteMany,
  onLoadUpdate,
  onLoadView,
  onUpdateArchiveStatus,
  recipes,
}) => {
  const [data, setData] = useState([])

  const [columns] = useState([
    { title: 'Title', field: 'title' },
    { title: 'Prep Time (min)', field: 'prepTime' },
    { title: 'Defrost', field: 'defrost' },
    { title: 'Servings', field: 'servings' },
    // { title: 'WW Points', field: 'wwPoints' }, // data no longer needed
    { title: 'Meal Type', field: 'mealType' },
  ])

  const handleArchiveClick = (recipe) => {
    const status = recipe.archived ? 'unarchived' : 'archived'
    onUpdateArchiveStatus({ ...recipe, archived: !recipe.archived }, status)
  }

  const handleDeleteManyClick = (data) => {
    const slugs = data.map((row) => row.slug)
    onDeleteMany(slugs)
  }

  const handleDuplicateRecipeClick = (recipe) => {
    recipe._id = ''
    onLoadUpdate(recipe)
  }

  const handleLoadUpdateClick = (recipe) => {
    onLoadUpdate(recipe)
  }

  const handleLoadViewClick = (recipe) => {
    onLoadView(recipe)
  }

  useEffect(() => {
    // Filter recipes based on archive status, or show all recipes if no filter given
    //  * allowed options include 'archived' and 'unarchived'
    const filteredRecipes =
      filter === 'archived'
        ? recipes.filter((recipe) => recipe.archived === true)
        : filter === 'unarchived'
        ? recipes.filter((recipe) => recipe.archived === false)
        : recipes

    setData(filteredRecipes)
  }, [recipes])

  return (
    <MaterialTable
      title='Recipes'
      columns={columns}
      data={data}
      components={{
        Action: (props) => {
          if (typeof props.action.icon === 'function') {
            return props.action.icon(props.data)
          }
          return <MTableAction {...props} />
        },
        Container: (props) => (
          <Paper {...props} elevation={filter === 'archived' ? 0 : 1} />
        ),
      }}
      localization={{
        body: {
          emptyDataSourceMessage: 'No recipes found',
          editRow: {
            deleteText: 'Delete recipe? This action cannot be undone.',
          },
        },
      }}
      options={{
        actionsColumnIndex: -1,
        exportButton: true,
        paging: false,
        selection: true,
        toolbar: true,
      }}
      actions={[
        {
          icon: 'visibility',
          onClick: (e, data) => handleLoadViewClick(data),
          position: 'row',
          tooltip: 'View recipe',
        },
        {
          icon: 'edit',
          onClick: (e, data) => handleLoadUpdateClick(data),
          position: 'row',
          tooltip: 'Update recipe',
        },
        {
          icon: 'library_add',
          onClick: (e, data) => handleDuplicateRecipeClick(data),
          position: 'row',
          tooltip: 'Copy recipe',
        },
        {
          action: (rowData) => ({
            icon: rowData.archived ? 'unarchive' : 'archive',
            onClick: (e, data) => handleArchiveClick(data),
            tooltip: rowData.archived ? 'Unarchive recipe' : 'Archive recipe',
          }),
          position: 'row',
        },
        {
          icon: (data) => (
            <BulkDeleteConfirmationAlert
              data={data}
              name='recipe'
              onDelete={handleDeleteManyClick}
            />
          ),
          position: 'toolbarOnSelect',
          tooltip: 'Delete selected recipes',
        },
      ]}
      editable={{
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            const newData = [...data]
            const index = oldData.tableData.id
            newData.splice(index, 1)
            setData([...newData])
            onDelete(oldData)
            resolve()
          }),
      }}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipesTable)
