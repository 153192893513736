import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'

import ShoppingList from './ShoppingList'
import StoreSelector from './StoreSelector'

import {
  PLAN_LIST_COPIED,
  PLAN_LIST_DIALOG_UNLOADED,
} from '../../../constants/actionTypes'

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  defaultStore: state.common.settings.defaultStore,
  ingredients: state.ingredients.ingredients,
  meals: state.meals.meals,
  recipes: state.recipes.recipes,
  stores: state.stores.stores,
  viewer: state.plans.viewer,
})

const mapDispatchToProps = (dispatch) => ({
  onCopy: () =>
    dispatch({
      type: PLAN_LIST_COPIED,
      snackbar: { message: 'List copied', variant: 'success' },
    }),
  onExit: () => dispatch({ type: PLAN_LIST_DIALOG_UNLOADED }),
})

const ListDialog = ({
  categories,
  defaultStore,
  ingredients,
  meals,
  onCopy,
  onExit,
  recipes,
  stores,
  viewer,
}) => {
  const [list, setList] = useState([])
  const [open, setOpen] = useState(false)
  const [store, setStore] = useState(defaultStore)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const getList = () => {
    if (viewer.plan) {
      if (categories.length === 0) return 'No categories found'

      // First we create an array of meal and recipe objects collected from each day of the plan
      let planMeals = []
      for (const [day, mealIds] of Object.entries(viewer.plan.meals)) {
        let dailyMeals = mealIds.map(
          (mealId) =>
            meals.find((meal) => meal._id === mealId) ??
            recipes.find((recipe) => recipe._id === mealId)
        )
        planMeals = [...planMeals, ...dailyMeals]
      }

      if (planMeals.length === 0) return 'No meals found'

      // Then we create an array of all recipe objects collected from each meal in the plan
      const planRecipes = planMeals.flatMap((meal) => {
        if (meal && meal.hasOwnProperty('recipes')) return meal.recipes
        return meal
      })

      if (planRecipes.length === 0) return 'No recipes found'

      // Then we create an array of objects with ingredient and category info
      const planIngredients = planRecipes
        .flatMap((recipe) =>
          recipe.ingredients.map((ingredient) => ({
            ...ingredient,
            recipe: recipe.title,
          }))
        )
        .map((ingredient) => {
          const item = ingredients.find((item) => item._id === ingredient.id)
          const itemCategory = categories.find(
            (category) => category._id === item.category
          )
          return {
            ...ingredient,
            category: itemCategory._id,
            name: item.name,
          }
        })

      if (planIngredients.length === 0) return 'No ingredients found'

      // Then we consolidate the arry of ingredients into an array of categories and their associated ingredients
      const ingredientsByCategory = planIngredients.reduce((obj, item) => {
        const ingredient = {
          id: item.id,
          name: item.name,
          quantity: item.quantity,
          recipes: [item.recipe],
          unit: item.unit,
        }
        if (obj.hasOwnProperty(item.category)) {
          obj[item.category].push(ingredient)
        } else {
          obj[item.category] = [ingredient]
        }
        return obj
      }, {})

      // Looping over the categories in the store, we consolidate the ingredients by name and unit into the list array
      const lists = []
      const selectedStore = stores.find((s) => s._id === store)
      const categoryList = selectedStore?.categories ?? categories
      categoryList.forEach((category) => {
        const ingredientsList = ingredientsByCategory[category._id] ?? []
        const ingredientTotals = ingredientsList.reduce((arr, item) => {
          const findItem = (i) => i.id === item.id && i.unit === item.unit
          if (arr.find((i) => findItem(i))) {
            const itemIndex = arr.findIndex((i) => findItem(i))
            arr[itemIndex] = {
              ...arr[itemIndex],
              quantity: arr[itemIndex].quantity + item.quantity,
              recipes: arr[itemIndex].recipes.concat(
                item.recipes.filter(
                  (item) => arr[itemIndex].recipes.indexOf(item) < 0
                )
              ),
            }
          } else {
            arr.push(item)
          }
          return arr
        }, [])
        lists.push(ingredientTotals)
      })

      return lists.flat()
    }

    return 'No plan found'
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCopy = () => {
    const listString = list
      .map((item) => `${item.name} (${item.quantity} ${item.unit})`)
      .join('\n')
    navigator.clipboard.writeText(listString).then(
      () => {
        onCopy()
      },
      (err) => {
        console.error('error', err)
      }
    )
  }

  const handleExit = () => {
    setStore(defaultStore)
    setList([])
    onExit()
  }

  const handleStoreChange = (storeId) => {
    setStore(storeId)
  }

  useEffect(() => {
    // Update the list when the dialog opens
    if (open) setList(getList())
  }, [open])

  useEffect(() => {
    // Open the dialog if a viewer.plan object is defined
    if (viewer.type === 'list') setOpen(!!viewer.plan)
  }, [viewer.plan])

  useEffect(() => {
    // Update the list when the store changes
    setList(getList())
  }, [store])

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth='md'
      onClick={(ev) => ev.stopPropagation()}
      onClose={handleClose}
      onFocus={(ev) => ev.stopPropagation()}
      open={open}
      TransitionProps={{
        onExit: handleExit,
      }}
    >
      <DialogTitle>Shopping List for {viewer.plan?.title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {stores.length > 0 && (
            <Grid item xs={12}>
              <StoreSelector
                defaultStore={defaultStore}
                onChange={handleStoreChange}
                stores={stores}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <ShoppingList list={list} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          disabled={!Array.isArray(list)}
          onClick={handleCopy}
          variant='contained'
        >
          Copy list
        </Button>
        <Button onClick={handleClose} variant='contained'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ListDialog)
