import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import Div100vh from 'react-div-100vh'
import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import { ArrowBackIos, InfoOutlined } from '@material-ui/icons'

import About from '../About'
import Logo from '../Logo'
import LoginForm from './LoginForm'
import background from '../../images/plate-background.png'

import { LOGIN_PAGE_UNLOADED } from '../../constants/actionTypes'

const useStyles = makeStyles((theme) => ({
  aboutLink: {
    bottom: theme.spacing(2),
    position: 'absolute',
    right: theme.spacing(4),
  },
  loginLink: {
    'marginBottom': theme.spacing(2),
    '& > a': {
      textDecoration: 'none',
    },
  },
  logo: {
    display: 'block',
    margin: '0 auto',
    width: 120,
  },
  page: {
    alignItems: 'center',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      backgroundSize: 'initial',
      justifyContent: 'flex-end',
    },
  },
  panel: {
    borderRadius: '1rem',
    maxHeight: '90%',
    overflowY: 'scroll',
    padding: '4rem 2rem',
    position: 'relative',
    width: '80%',
    [theme.breakpoints.up('lg')]: {
      borderRadius: '0',
      height: '100%',
      maxHeight: '100%',
      width: '40%',
    },
  },
  progress: {
    height: 10,
    left: '0',
    position: 'absolute',
    top: '0',
    width: '100%',
  },
}))

const mapStateToProps = (state) => ({
  ...state.auth,
  currentUser: state.common.currentUser,
  pathname: state.router.location.pathname,
})

const mapDispatchToProps = (dispatch) => ({
  onUnload: () => dispatch({ type: LOGIN_PAGE_UNLOADED }),
})

const AboutLink = () => {
  const classes = useStyles()
  return (
    <span className={classes.aboutLink}>
      <Tooltip arrow enterTouchDelay={0} title='Learn more'>
        <Link to='/about'>
          <InfoOutlined color='secondary' fontSize='small' />
        </Link>
      </Tooltip>
    </span>
  )
}

const LoginLink = () => {
  const classes = useStyles()
  return (
    <div className={classes.loginLink}>
      <Link to='/login'>
        <Button color='secondary' startIcon={<ArrowBackIos />}>
          Login
        </Button>
      </Link>
    </div>
  )
}

const Login = ({ currentUser, inProgress, onUnload, pathname }) => {
  const classes = useStyles()

  useEffect(() => {
    return () => onUnload()
  }, [])

  if (currentUser) {
    return <Redirect to='/' />
  }

  return (
    <Div100vh>
      <div className={classes.page}>
        <Paper elevation={12} className={classes.panel}>
          {inProgress && <LinearProgress className={classes.progress} />}
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Logo className={classes.logo} />
            </Grid>
            <Grid item xs={12}>
              {pathname.includes('about') ? (
                <>
                  <LoginLink />
                  <About />
                </>
              ) : (
                <>
                  <AboutLink />
                  <LoginForm />
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </div>
    </Div100vh>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
