import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import PageHeader from '../PageHeader'
import UserDialog from './UserDialog'
import UserList from './UserList'
import agent from '../../agent'

import {
  MANAGE_USERS_PAGE_LOADED,
  MANAGE_USERS_PAGE_UNLOADED,
} from '../../constants/actionTypes'

const mapStateToProps = (state) => ({
  ...state.users,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({
      type: MANAGE_USERS_PAGE_LOADED,
      payload: agent.Users.getAll(),
    }),
  onUnload: () => dispatch({ type: MANAGE_USERS_PAGE_UNLOADED }),
})

const ManageUsers = ({ onLoad, onUnload, users }) => {
  useEffect(() => {
    onLoad()

    return () => {
      onUnload()
    }
  }, [])

  return (
    <>
      <PageHeader title={'Manage Users'}>
        <UserDialog />
      </PageHeader>
      <UserList users={users} />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers)
