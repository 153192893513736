import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Spinner from '../Spinner'
import StoresTable from './StoresTable'

import {
  STORES_PAGE_LOADED,
  STORES_PAGE_UNLOADED,
} from '../../constants/actionTypes'
import agent from '../../agent'

const mapStateToProps = (state) => ({
  ...state.stores,
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: () =>
    dispatch({
      type: STORES_PAGE_LOADED,
      payload: Promise.all([agent.Stores.getAll(), agent.Categories.getAll()]),
    }),
  onUnload: () => dispatch({ type: STORES_PAGE_UNLOADED }),
})

const Stores = ({ onLoad, onUnload, pageLoaded }) => {
  useEffect(() => {
    onLoad()

    return () => {
      onUnload()
    }
  }, [])

  return <>{pageLoaded ? <StoresTable /> : <Spinner />}</>
}

export default connect(mapStateToProps, mapDispatchToProps)(Stores)
