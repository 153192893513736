import React from 'react'
import MaterialTable, { MTableEditRow } from '@material-table/core'

/**
 * This is a fix for an issue with material-table that causes the enter keypress to
 * trigger a row action rather than act to control the input elements (i.e., instead
 * of selecting an option from a select element, pressing enter causes the form to
 * submit without the option being selected.)
 *
 * Source: https://github.com/mbrn/material-table/pull/2008#issuecomment-662529834
 */
const MaterialTableFix = (props) => {
  return (
    <MaterialTable
      components={{
        EditRow: (props) => (
          <MTableEditRow
            {...props}
            onKeyDown={(e) => {
              if (e.keyCode === 27)
                props.onEditingCanceled(props.mode, props.data)
            }}
          />
        ),
      }}
      {...props}
    />
  )
}

export default MaterialTableFix
