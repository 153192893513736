import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { Button, Grid } from '@material-ui/core'
import * as Yup from 'yup'

import ListErrors from '../ListErrors'
import agent from '../../agent'

import { LOGIN } from '../../constants/actionTypes'

const mapStateToProps = (state) => ({ ...state.auth })

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (username, password) =>
    dispatch({ type: LOGIN, payload: agent.Auth.login(username, password) }),
})

const LoginForm = (props) => {
  const submitForm = (username, password) => {
    props.onSubmit(username, password)
  }

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      validationSchema={Yup.object({
        username: Yup.string().required('Required'),
        password: Yup.string().required('Required'),
      })}
      onSubmit={(values) => {
        const { username, password } = values
        submitForm(username, password)
      }}
    >
      <Form>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ListErrors errors={props.errors} />
            <Field
              component={TextField}
              disabled={props.inProgress}
              fullWidth
              label='Username'
              name='username'
              type='text'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={TextField}
              disabled={props.inProgress}
              fullWidth
              label='Password'
              name='password'
              type='password'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color='primary'
              disabled={props.inProgress}
              size='large'
              type='submit'
              variant='contained'
            >
              {props.inProgress ? 'Loading...' : 'Login'}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
