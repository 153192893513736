import {
  APP_LOAD,
  LOGIN,
  LOGIN_PAGE_UNLOADED,
  LOGOUT,
  NAV_LOADED,
  NAV_TAB_CHANGE,
  REDIRECT,
  REGISTER,
  REGISTER_PAGE_UNLOADED,
  SET_DEFAULT_STORE,
  SET_STARRED_PLAN,
  SETTINGS_SAVED,
  SETTINGS_PAGE_UNLOADED,
} from '../constants/actionTypes'

const defaultState = {
  token: null,
  viewChangeCounter: 0,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        currentUser: action.payload ? action.payload.user : null,
        settings: action.payload ? action.payload.settings : null,
      }
    case LOGOUT:
      return { ...state, redirectTo: '/login', token: null, currentUser: null }
    case LOGIN:
    case REGISTER:
      return {
        ...state,
        redirectTo: action.error
          ? null
          : action.payload.user.role === 'admin'
          ? '/users'
          : '/plans',
        token: action.error ? null : action.payload.user.token,
        currentUser: action.error ? null : action.payload.user,
        settings: action.error ? null : action.payload.settings,
      }
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
    case SETTINGS_PAGE_UNLOADED:
      return { ...state, viewChangeCounter: state.viewChangeCounter + 1 }
    case NAV_LOADED:
    case NAV_TAB_CHANGE:
      return { ...state, redirectTo: action.redirectTo }
    case REDIRECT:
      return { ...state, redirectTo: null }
    case SET_DEFAULT_STORE:
    case SET_STARRED_PLAN:
      return {
        ...state,
        settings: action.error ? state.settings : action.payload.settings,
      }
    case SETTINGS_SAVED:
      return {
        ...state,
        currentUser: action.error ? state.currentUser : action.payload[0].user,
        settings: action.error ? state.settings : action.payload[1].settings,
      }
    default:
      return state
  }
}
