import React, { useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'

import WeekdayDroppable from './WeekdayDroppable'
import { DAYS } from '../../../constants/days'

const PlanBuilder = ({ meals, mealPlan, name, onChange }) => {
  const [plans, setPlans] = useState(mealPlan)

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result

    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return
    }

    const start = source.droppableId
    const finish = destination.droppableId
    const mealId = draggableId.split('-')[1]

    const newMealPlan = { ...mealPlan }
    newMealPlan[start].splice(source.index, 1)
    newMealPlan[finish].splice(destination.index, 0, mealId)

    setPlans(newMealPlan)
    onChange(name, newMealPlan)
    return
  }

  const handleAddClick = (result) => {
    const { day, mealId } = result
    const newMealPlan = { ...mealPlan }
    newMealPlan[day].push(mealId)
    setPlans(newMealPlan)
    onChange(name, newMealPlan)
  }

  const handleRemoveClick = (e) => {
    const id = e.currentTarget.getAttribute('id')
    const [day, mealId] = id.split('-')
    const newMealPlan = { ...mealPlan }
    const removeIndex = newMealPlan[day].indexOf(mealId)
    newMealPlan[day].splice(removeIndex, 1)
    setPlans(newMealPlan)
    onChange(name, newMealPlan)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {DAYS.map((day) => (
        <WeekdayDroppable
          day={day}
          key={day}
          meals={meals}
          mealPlan={plans[day]}
          onAdd={handleAddClick}
          onRemove={handleRemoveClick}
        />
      ))}
    </DragDropContext>
  )
}

export default PlanBuilder
