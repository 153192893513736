import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'

import FabAdd from '../FabAdd'
import ListErrors from '../ListErrors'
import PlanBuilder from './PlanBuilder/PlanBuilder'

import {
  ADD_PLAN,
  PLAN_DIALOG_UNLOADED,
  UPDATE_PLAN,
} from '../../constants/actionTypes'
import { DAYS } from '../../constants/days'
import agent from '../../agent'

const mapStateToProps = (state) => ({
  ...state.plans,
  meals: state.meals.meals,
  recipes: state.recipes.recipes,
})

const mapDispatchToProps = (dispatch) => ({
  onAdd: (plan) =>
    dispatch({
      type: ADD_PLAN,
      payload: agent.Plans.add(plan),
      snackbar: { message: 'Plan added', variant: 'success' },
    }),
  onExit: () => dispatch({ type: PLAN_DIALOG_UNLOADED }),
  onUpdate: (plan) =>
    dispatch({
      type: UPDATE_PLAN,
      payload: agent.Plans.update(plan),
      snackbar: { message: 'Plan updated', variant: 'success' },
    }),
})

/**
 * Casts recipes that are meals into meal-like objects, combines them
 * with the meals array, and returns the new array sorted by title
 */
const getAllMeals = (meals, recipes) => {
  const allMeals = [...meals]
  const recipesAsMeals = recipes
    .filter((recipe) => recipe.isMeal)
    .map((recipe) => ({
      mealType: recipe.mealType,
      notes: recipe.notes,
      recipes: [recipe],
      slug: recipe.slug,
      title: recipe.title,
      archived: recipe.archived,
      _id: recipe._id,
    }))
  const combinedMeals = [...allMeals, ...recipesAsMeals].sort((a, b) =>
    a.title.localeCompare(b.title)
  )
  return combinedMeals
}

const PlanEditor = ({
  editor,
  errors,
  inProgress,
  meals,
  onAdd,
  onExit,
  onUpdate,
  recipes,
}) => {
  const allMeals = getAllMeals(meals, recipes)

  const defaultMeals = DAYS.reduce((obj, day) => ((obj[day] = []), obj), {})

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmitAdd = (values) => {
    onAdd({ ...values })
  }

  const handleSubmitUpdate = (values) => {
    onUpdate({ ...editor.plan, ...values })
  }

  useEffect(() => {
    // Close the dialog only if there are no errors
    if (!inProgress && !errors) setOpen(false)
  }, [inProgress])

  useEffect(() => {
    // Open the dialog if an editor.plan object is defined
    setOpen(!!editor.plan)
  }, [editor.plan])

  return (
    <>
      <FabAdd item='plan' onClick={handleClickOpen} />
      <Dialog
        fullScreen
        onClick={(ev) => ev.stopPropagation()}
        onClose={handleClose}
        onFocus={(ev) => ev.stopPropagation()}
        open={open}
        scroll='body'
        TransitionProps={{
          onExit: onExit,
        }}
      >
        <Formik
          initialValues={{
            meals: editor.plan?.meals || defaultMeals,
            notes: editor.plan?.notes || '',
            order: editor.plan?.order || 0, // currently not in use
            skip: editor.plan?.skip || false, // currently not in use
            title:
              !editor.plan?._id && editor.plan?.title
                ? `Copy of ${editor.plan?.title}`
                : editor.plan?.title || '',
          }}
          validationSchema={Yup.object({
            meals: Yup.object(),
            notes: Yup.string(),
            order: Yup.number(),
            skip: Yup.boolean(),
            title: Yup.string().required('Required'),
          })}
          onSubmit={async (values) => {
            editor.plan?._id
              ? handleSubmitUpdate(values)
              : handleSubmitAdd(values)
          }}
        >
          {(formik) => (
            <Container disableGutters>
              <Form>
                <DialogTitle>
                  {editor.plan?._id ? 'Update plan' : 'Add plan'}
                </DialogTitle>
                <DialogContent>
                  <ListErrors errors={errors} />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        autoFocus={!editor.plan}
                        component={TextField}
                        disabled={inProgress}
                        id='title'
                        label='Plan Title'
                        name='title'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        disabled={inProgress}
                        id='notes'
                        label='Notes'
                        name='notes'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='h6'>Meals</Typography>
                      <PlanBuilder
                        meals={allMeals}
                        mealPlan={formik.values.meals}
                        name='meals'
                        onChange={formik.setFieldValue}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    color='primary'
                    disabled={inProgress}
                    type='submit'
                    variant='contained'
                  >
                    {editor.plan?._id
                      ? inProgress
                        ? 'Updating...'
                        : 'Update'
                      : inProgress
                      ? 'Adding...'
                      : 'Add'}
                  </Button>
                  <Button
                    disabled={inProgress}
                    onClick={handleClose}
                    variant='contained'
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Form>
            </Container>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanEditor)
